import React from 'react';
import { Form, Input} from 'semantic-ui-react';


const CheackBoxInput = ({input, width, type, label, toggle, id, setvalue, checked, disabled, labelStyle }) =>{

  return(
    <Form.Field>
    <div className={ `ui ${ toggle ? 'cheackbox ' : 'cheackbox' }` } >
      
      
      <Input
        id={ id } 
        { ...input } 
        value={ setvalue } 
        type='checkbox' 
        checked={ checked } 
        disabled={ disabled }
        style={{width: '20px', verticalAlign: 'center', fontSize: '1.3em'}}
        
      />
    <label className='ui label label'  htmlFor={ id }><span style={ labelStyle }>{ label }</span></label>
    </div>
  </Form.Field>
  )
}

export default CheackBoxInput;