import React, { Component } from 'react'
import { Accordion, Icon } from 'semantic-ui-react'

class BirthingBuddyAccordion extends Component {
  state = { activeIndex: -1 }

  handleClick = (e, titleProps) => {
    const { index } = titleProps
    const { activeIndex } = this.state
    const newIndex = activeIndex === index ? -1 : index

    this.setState({ activeIndex: newIndex })
  }

  render() {
    const { activeIndex } = this.state

    return (
      <Accordion>
        <Accordion.Title
          active={ activeIndex === 0 }
          index={ 0 }
          onClick={ this.handleClick }
        >
          <Icon name='dropdown'/>
          <b>What is a birthing buddy and what is their function?</b>
        </Accordion.Title>
        <Accordion.Content active={ activeIndex === 0 }>
          <p>
            A birthing buddy is someone you choose to help communicate with us just before and after your
            baby is born. It could be a spouse, friend or family member – just someone who can send replies to
            our SMSes so that you don’t have to ☺. And can also follow your journey with you on the
            microsite. You will get to choose your birthing buddy on the microsite from around week 22.
          </p>
        </Accordion.Content>
      </Accordion>
    )
  }
}

export default BirthingBuddyAccordion;