import { createReducer } from '../store/createReducer';
import { MODAL_OPEN, MODAL_CLOSE, MODAL_CLOSEALL } from './modalConstants';


const animateFadeInTime = 500;
const animateFadeOutTime = 400;

const initialState = {
  modals: [],
  animateFadeInTime: animateFadeInTime,
  animateFadeOutTime: animateFadeOutTime
};

const openModal = (state, payload) => {
  const { modalType, modalProps } = payload;
  const animateInTime = state.modals.length === 0 ? animateFadeInTime : 0;
  const animateOutTime = state.modals.length === 0 ? animateFadeOutTime : 0;
  return {
    modals: [...state.modals, { modalType, modalProps }],
    animateFadeInTime: animateInTime,
    animateFadeOutTime: animateOutTime
  }
};

const closeModal = (state, payload) => {
  return {
    modals: [...state.modals.filter(modal => modal.modalType !== payload.modalType)],
    animateFadeInTime: 0,
    animateFadeOutTime: 0
  }
};

const closeAllModals = state => {
  return {
    modals: [],
    animateFadeInTime: animateFadeInTime,
    animateFadeOutTime: animateFadeOutTime
  };
};

export default createReducer(initialState, {
  [MODAL_OPEN]: openModal,
  [MODAL_CLOSE]: closeModal,
  [MODAL_CLOSEALL]: closeAllModals
});
