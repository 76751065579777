import React, { Component } from "react";
import { Accordion, Icon } from "semantic-ui-react";

class SwagBagAccordion extends Component {
	state = { activeIndex: -1 };

	handleClick = (e, titleProps) => {
		const { index } = titleProps;
		const { activeIndex } = this.state;
		const newIndex = activeIndex === index ? -1 : index;

		this.setState({ activeIndex: newIndex });
	};

	render() {
		const { activeIndex } = this.state;

		return (
			<Accordion>
				<Accordion.Title
					active={activeIndex === 0}
					index={0}
					onClick={this.handleClick}
				>
					<Icon name="dropdown" />
					<b>What’s in the bag?</b>
				</Accordion.Title>
				<Accordion.Content active={activeIndex === 0}>
					<p>
						We’ve put together a gorgeous designer bag with a great selection of
						products for moms. These items differ per month, but we’ve got you covered
						with some lovely things to take care of yourself.
					</p>
				</Accordion.Content>

				<Accordion.Title
					active={activeIndex === 1}
					index={1}
					onClick={this.handleClick}
				>
					<Icon name="dropdown" />
					<b>When do I get the bag?</b>
				</Accordion.Title>
				<Accordion.Content active={activeIndex === 1}>
					<p>
						You should get your bag around 21 to 30 working days from the time you’ve
						replied to the MyMomentumBabyYumYum SMS notifying you of the upcoming
						delivery of your bag, which is from 20 to 24 weeks.
					</p>
				</Accordion.Content>

				<Accordion.Title
					active={activeIndex === 2}
					index={2}
					onClick={this.handleClick}
				>
					<Icon name="dropdown" />
					<b>How do you know where to deliver the bag to?</b>
				</Accordion.Title>
				<Accordion.Content active={activeIndex === 2}>
					<p>
						We will send you an SMS requesting your address. Please note that this
						must be a physical address, and not a PO Box. If you don’t receive your
						SMS, please contact the call centre on
						<a href="tel:0878970359"> 087 897 0359</a>, or email
						<a href="mailto:momentum@babyyumyum.co.za"> momentum@babyyumyum.co.za</a>
					</p>
				</Accordion.Content>

				<Accordion.Title
					active={activeIndex === 3}
					index={3}
					onClick={this.handleClick}
				>
					<Icon name="dropdown" />
					<b>How is the bag delivered?</b>
				</Accordion.Title>
				<Accordion.Content active={activeIndex === 3}>
					<p>Your bag will be delivered via courier.</p>
				</Accordion.Content>

				<Accordion.Title
					active={activeIndex === 4}
					index={4}
					onClick={this.handleClick}
				>
					<Icon name="dropdown" />
					<b>I am having twins so do I get 2 bags?</b>
				</Accordion.Title>
				<Accordion.Content active={activeIndex === 4}>
					<p>
						Since this is a bag for moms and not babies, you will only get one bag.
					</p>
				</Accordion.Content>
				<Accordion.Title
					//active={activeIndex === 5}
					active={true}
					index={5}
					onClick={this.handleClick}
				>
					<Icon name="dropdown" />
					<b>Who qualifies for the swag bag?</b>
				</Accordion.Title>
				<Accordion.Content /*active={activeIndex === 5}*/ active={true}>
					<p>
						Only members on the Momentum Summit, Extender and Incentive Scheme Options
						qualify to receive the MyMomentum BabyYumYum Swag Bag.
					</p>
				</Accordion.Content>
			</Accordion>
		);
	}
}

export default SwagBagAccordion;
