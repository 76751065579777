import React, { Component } from "react";
import { toastr } from "react-redux-toastr";
import { isMobileView } from "../../app/common/utils/common";
import {
  Button,
  Grid,
  Form,
  Header,
  Radio,
  Input,
  Message,
  Checkbox
} from "semantic-ui-react";
import { GridColumn } from "semantic-ui-react";
import { loadPerson } from "../../features/nav/Sidebar/data/sideBarActions";
import {
  loadQuestion,
  submitAnswer
} from "../progressiveProfiling/data/progressiveProfilingQuestionsActions";
import { compose } from "redux";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import { withRouter } from "react-router";
import LoadingComponent from "../../app/layout/LoadingComponent";

const mapState = state => ({
  person: state.sideBar.person,
  questions: state.progressiveProfiling.questions,
  loading: state.progressiveProfiling.loading
});

const actions = {
  loadPerson,
  loadQuestion,
  submitAnswer
};

class ProgressiveProfilingQuestions extends Component {
  state = {
    showQuestions: false,
    profileUpdate: false,
    loadedInfo: false,
    showResponse: false,
    responseText: ""
  };

  async componentDidMount() {
    await this.props.loadQuestion(localStorage.getItem("BEDD"));

    const { questions } = this.props;
    // try{
    //   if (questions.ProfilingQuestions.length > 0) {
    //     localStorage.setItem('questionAmount', questions.ProfilingQuestions.length);
    //     localStorage.setItem('currentQuestionAmmount', 0);
    //   }
    // }
    // catch{}
    this.setState({ loadedInfo: true });
  }

  handleChange = (e, { value }) => {
    this.setState({ value });
    const { questions } = this.props;
    for (
      let i = 0;
      i < questions.ProfilingQuestions[0].Questions[0].AnswerOptions.length;
      i++
    ) {
      let _newanswerID =
        questions.ProfilingQuestions[0].Questions[0].AnswerOptions[i]
          .AnswerOptionID;
      let _answerResponse =
        questions.ProfilingQuestions[0].Questions[0].AnswerOptions[i]
          .AnswerResponse;
      if (_newanswerID === value && _answerResponse !== null) {
        this.setState({
          showResponse: true,
          responseText:
            questions.ProfilingQuestions[0].Questions[0].AnswerOptions[i]
              .AnswerResponse
        });

        i = questions.ProfilingQuestions[0].Questions[0].AnswerOptions.length;
      } else {
        this.setState({
          showResponse: false,
          responseText: ""
        });
      }
    }
  };

  handleSubmitQuestion = async value => {
    const { person, questions } = this.props;
    let sendValues;

    if (questions.ProfilingQuestions[0].Questions[0].ReactType === "radio") {
      sendValues = {
        SurveyQuestionID:
          questions.ProfilingQuestions[0].Questions[0].QuestionID,
        SurveyAnswerID: this.state.value,
        SurveyAnswerDesc: null
      };
    }
    if (questions.ProfilingQuestions[0].Questions[0].ReactType === "text") {
      sendValues = {
        SurveyQuestionID:
          questions.ProfilingQuestions[0].Questions[0].QuestionID,
        SurveyAnswerID: null,
        SurveyAnswerDesc: this.state.value
      };
    }

    try {
      this.setState({ submitting: true, loadedInfo: false });
      await this.props.submitAnswer(sendValues);

      for (
        let i = 0;
        i < questions.ProfilingQuestions[0].Questions[0].AnswerOptions.length;
        i++
      ) {
        let _newanswerID =
          questions.ProfilingQuestions[0].Questions[0].AnswerOptions[i]
            .AnswerOptionID;
        let _answerResponse =
          questions.ProfilingQuestions[0].Questions[0].AnswerOptions[i]
            .AnswerResponse;
        if (_newanswerID === this.state.value && _answerResponse !== null) {
          toastr.info("Message", _answerResponse);
        }
      }
      let amount = parseInt(localStorage.getItem("currentQuestionAmmount")) + 1;
      if (parseInt(localStorage.getItem("questionAmount")) === amount) {
        this.props.history.push(`/content/${person.ContentPageID}`);
      } else {
        localStorage.setItem("currentQuestionAmmount", amount);
      }
    } catch {
    } finally {
      this.setState({
        submitting: false,
        loadedInfo: false,
        showResponse: false
      });
      await this.props.loadQuestion(localStorage.getItem("BEDD"));
      this.setState({ loadedInfo: true });
    }
  };
  bui;
  profileUpdateClickHandler = () => {
    this.props.history.push("/myprofile");
  };

  render() {
    const { loading, questions, handleSubmit, submitting } = this.props;
    let questionOptions = [];
    let _answerResponse;
    if (
      this.state.loadedInfo &&
      localStorage.getItem("questionAmount") !==
        localStorage.getItem("currentQuestionAmmount")
    ) {
       try {
        if (questions.ProfilingQuestions.length > 0) {
          let _questionTypeCode =
            questions.ProfilingQuestions[0].Questions[0].QuestionTypeCode;
           
          if (_questionTypeCode === "PU") {
            this.setState({ profileUpdate: true });
           
          } else if (_questionTypeCode === "RB") {
            let _type = questions.ProfilingQuestions[0].Questions[0].ReactType;
            for (
              let i = 0;
              i <
              questions.ProfilingQuestions[0].Questions[0].AnswerOptions.length;
              i++
            ) {
              let _textAwnser =
                questions.ProfilingQuestions[0].Questions[0].AnswerOptions[i]
                  .AnswerOptionText;

              let _questionID =
                questions.ProfilingQuestions[0].Questions[0].QuestionID;

              let _component =
                questions.ProfilingQuestions[0].Questions[0].ReactComponent;

              let _answerID =
                questions.ProfilingQuestions[0].Questions[0].AnswerOptions[i]
                  .AnswerOptionID;
              _answerResponse =
                questions.ProfilingQuestions[0].Questions[0].AnswerOptions[i]
                  .AnswerResponse;
              let sendValues = { QuestionID: _questionID, AnswerID: _answerID };

              if (_type === "radio") {
                questionOptions.push(
                  <div key={_answerID}>
                    <Radio
                      id={_answerID}
                      value={_answerID}
                      type={_type}
                      checked={this.state.value === _answerID}
                      onChange={this.handleChange}
                    />
                    <label
                      className="byy-text-darkblue"
                      htmlFor={_questionID}
                      value={_answerResponse}
                    >
                      {_textAwnser}
                    </label>
                  </div>
                );
              }

              if (_type === "cheakbox") {
                questionOptions.push(
                  <div key={_answerID}>
                    <Checkbox
                      id={_answerID}
                      value={_answerID}
                      type={_type}
                      checked={this.state.value === _answerID}
                      onChange={this.handleChange}
                    />
                    <label
                      className="byy-text-darkblue"
                      htmlFor={_questionID}
                      value={_answerResponse}
                    >
                      {_textAwnser}
                    </label>
                  </div>
                );
              }
            }
          } else if (_questionTypeCode === "TB") {
            let _type = questions.ProfilingQuestions[0].Questions[0].ReactType;
            if (_type === "text") {
             
              questionOptions.push(
                <div style={{ textAlign: "center" }}>
                  <Input
                    placeholder="Type here..."
                    type={_type}
                    value={this.state.value}
                    onChange={this.handleChange}
                  ></Input>
                </div>
              );
            }
          }
        }
      } catch {}
    }
    return (
      <div style={{ position: "relative" }}>
        <div
          className="byy-lighterblue"
          style={{
            borderRadius: "5px",
            position: "absolute",
            width: 300,
            height: 460,
            margin: isMobileView() ? "5px 5px 5px 15px" : "65px 0 0 45px"
          }}
        />
        <div
          style={{
            position: "absolute",
            width: 300,
            height: 260,
            padding: "25px 20px 25px 20px",
            margin: isMobileView() ? "20px 0 0 1px" : "70px 0 0 50px"
          }}
        >
          <Grid verticalAlign="middle" stackable>
            <GridColumn>
              <Header
                as="h2"
                className="byy-text-darkblue"
                textAlign="left"
                style={{ fontWeight: "normal" }}
              >
                <p>
                  Welcome {localStorage.getItem("FirstName")} <b>please </b>
                  answer the <b> following!</b>
                </p>
              </Header>
              <Form
                onSubmit={handleSubmit(this.handleSubmitQuestion)}
                className="byy-text-darkblue"
                style={{ textAlign: "left", fontWeight: "bold", margin: "2" }}
              >
                {!this.state.loadedInfo && <LoadingComponent />}
                {this.state.loadedInfo &&
                  localStorage.getItem("questionAmount") !==
                    localStorage.getItem("currentQuestionAmmount") && (
                    <div>
                      <Header
                        as="h5"
                        className="byy-text-darkblue"
                        textAlign="left"
                        style={{ fontWeight: "normal" }}
                      >
                        <p>
                          {
                            questions.ProfilingQuestions[0].Questions[0]
                              .QuestionDesc
                          }
                        </p>
                      </Header>
                      <Form.Field>{questionOptions}</Form.Field>
                      {this.state.showResponse && (
                        <Message color="pink">
                          {this.state.responseText}
                        </Message>
                      )}

                      {!this.state.profileUpdate && (
                        <Button
                          style={{ marginTop: 20, marginLeft: 0 }}
                          className="ui red"
                          content="Submit"
                          position="absolute"
                          loading={submitting}
                          disabled={submitting}
                          //pass {this.state.value} to API once ready
                        />
                      )}
                      {this.state.profileUpdate && (
                        <Button
                          style={{ marginTop: 20, marginLeft: 0 }}
                          className="ui red"
                          content="Update Profile"
                          position="absolute"
                          onClick={this.profileUpdateClickHandler}
                          //pass {this.state.value} to API once ready
                        />
                      )}
                    </div>
                  )}
              </Form>
            </GridColumn>
          </Grid>
        </div>
      </div>
    );
  }
}

export default compose(
  withRouter,
  connect(mapState, actions),
  reduxForm({
    form: "ProgressiveProfilingQuestions",
    enableReinitialize: true,
    destroyOnUnmount: false
  })
)(ProgressiveProfilingQuestions);
