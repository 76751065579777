import { isLoggedIn, loginUser } from "../../auth/authActions";
import React, { Component } from "react";
import { Button, Form, Grid, Header } from "semantic-ui-react";
import { Field, reduxForm } from "redux-form";
import DateInput from "../../../app/common/form/DateInput";
import { compose } from "redux";
import { connect } from "react-redux";
import { combineValidators, isRequired } from "revalidate";
import PhoneNumberInput from "../../../app/common/form/PhoneNumberInput";
import { withRouter } from "react-router";
import { isMobileView } from "../../../app/common/utils/common";
import { loadPerson } from "../../nav/Sidebar/data/sideBarActions";
import { loadQuestion } from "../../progressiveProfiling/data/progressiveProfilingQuestionsActions";
import DropdownDateInput from "../../../app/common/form/DropdownDateInput";
import { parseISO } from "date-fns";

const mapState = (state) => ({
  person: state.sideBar.person,
  questions: state.progressiveProfiling.questions,
});

const actions = {
  loginUser,
  loadPerson,
  loadQuestion,
};

export const validate = combineValidators({
  cellNumber: isRequired({ message: "Cell Number is required" }),
  //dob: isRequired({ message: 'Date of Birth is required' })
});

class LoginForm extends Component {
  constructor(props) {
    super(props);
    this.state = { year: null, month: null, day: null };
  }
  state = {
    submitting: false,
  };

  //
  // componentDidMount() {
  //   this.props.loadPerson();
  //   this.props.loadQuestion();
  // }

  async componentDidUpdate() {
    const { person, questions } = this.props;
    if (isLoggedIn() && person !== null) {
      //this.props.history.push(`/content/${ person.ContentPageID }`);

      localStorage.setItem("FirstName", person.FirstName);
      localStorage.setItem("LastName", person.LastName);
      localStorage.setItem("DOB", person.DOB);
      localStorage.setItem("WeeksPregnant", person.WeeksPregnant);
      localStorage.setItem("Address", person.Address);
      localStorage.setItem("EDB", person.EstimatedBirthDate);
      localStorage.setItem("EDD", person.EstimatedDeliveryDate);
      localStorage.setItem("SchemeOption", person.SchemeOption);
      localStorage.setItem("ContentPageID", person.ContentPageID);
      localStorage.setItem("BabySize", person.BabySize);
      localStorage.setItem("BEDD", person.BenefitExpectedDueDate);
      // localStorage.setItem("BEDD", "2020-02-25T00:00:00");

      try {
        if (questions.ProfilingQuestions.length > 0) {
          localStorage.setItem(
            "questionAmount",
            questions.ProfilingQuestions.length
          );
          localStorage.setItem("currentQuestionAmmount", 0);
        }
      } catch {}
    }
  }

  handleLogin = async (values) => {
    console.log(values);
    let loginDate =
      localStorage.getItem("loginYear") +
      localStorage.getItem("loginMonth") +
      localStorage.getItem("loginDay");
    let convertLoginDate = loginDate
      ? Object.prototype.toString.call(loginDate) !== "[object Date]"
        ? parseISO(loginDate)
        : loginDate
      : null;
    let toSubmit;
    toSubmit = {
      cellNumber: values.cellNumber,
      dob: convertLoginDate,
    };
    console.log(toSubmit);
    try {
      const contentPageID = await this.props.loginUser(toSubmit);
      await this.props.loadPerson();
      localStorage.removeItem("loginYear");
      localStorage.removeItem("loginMonth");
      localStorage.removeItem("loginDay");

      try {
        await this.props.loadQuestion(localStorage.getItem("BEDD"));
        // await this.props.loadQuestion("2020-02-26T00:00:00");
      } catch {
        this.props.history.push(`/content/${contentPageID}`);
      }

      const { person, questions } = this.props;

      this.setState({ submitting: true });

      if (
        person.MonthHasPassed &&
        person.PersonType === "M" &&
        person.EventTypeCode === "P"
      ) {
        this.props.history.push("/verifyDueDate");
      } else if (
        !person.MonthHasPassed &&
        questions.ProfilingQuestions.length > 0
      ) {
        this.props.history.push("/progressiveProfiling");
      } else if (
        person.MonthHasPassed &&
        questions.ProfilingQuestions.length > 0
      ) {
        this.props.history.push("/progressiveProfiling");
      } else {
        this.props.history.push(`/content/${contentPageID}`);
      }
    } catch (error) {
      // An error occured - the toast was thrown in the action
    } finally {
      this.setState({ submitting: false });
    }
  };

  render() {
    const { handleSubmit, submitting } = this.props;

    return (
      <div style={{ position: "relative" }}>
        <div
          className="byy-lighterblue"
          style={{
            borderRadius: "50%",
            position: "absolute",
            width: 355,
            height: 355,
            margin: isMobileView() ? "20px 0 10 10px" : "50px 0 0 50px",
          }}
        />
        <div
          style={{
            borderRadius: "50%",
            position: "absolute",
            width: 300,
            height: 300,
            padding: "35px 0 20px 100px",
            margin: isMobileView() ? "20px 0 0 -35px" : "50px 0 0 50px",
          }}
        >
          <Grid textAlign="center" verticalAlign="middle" stackable>
            <Grid.Column style={{ maxWidth: 300 }}>
              <Header
                as="h2"
                className="byy-text-darkblue"
                textAlign="left"
                style={{ fontWeight: "normal" }}
              >
                <b>Login</b> for your <b>Personalised</b> Journey
              </Header>
              <Form
                autoComplete="off"
                onSubmit={handleSubmit(this.handleLogin)}
                className="byy-text-darkblue"
                style={{ textAlign: "left", fontWeight: "bold", margin: "2" }}
              >
                <Field
                  name="cellNumber"
                  component={PhoneNumberInput}
                  label="Cell Number"
                  setFocus
                />
                {/* <Field
                  name='dob'
                  component={ DateInput }
                  label='Date of Birth'
                  dateFormat={ 'yyyyMMdd' }
                  minDate={ new Date().setFullYear(
                    new Date().getFullYear() - 100
                  ) }
                  maxDate={ new Date().setFullYear(
                    new Date().getFullYear() - 10
                  ) }
                /> */}
                <Field
                  name="dob"
                  component={DropdownDateInput}
                  label="Date of Birth"
                  year={this.state.year}
                  month={this.state.month}
                  day={this.state.day}
                  minDate={new Date().setFullYear(
                    new Date().getFullYear() - 100
                  )}
                  maxDate={new Date().setFullYear(
                    new Date().getFullYear() - 10
                  )}
                />
                <Button
                  style={{ marginTop: 20, marginLeft: 0 }}
                  loading={submitting}
                  disabled={submitting}
                  className="ui red"
                  content="Login"
                />
              </Form>
            </Grid.Column>
          </Grid>
        </div>
        <br/>
      </div>
    );
  }
}

export default compose(
  withRouter,
  connect(mapState, actions),
  reduxForm({
    form: "loginForm",
    validate,
    enableReinitialize: true,
    destroyOnUnmount: false,
  })
)(LoginForm);
