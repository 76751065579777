import React, { Fragment } from "react";
import "./SideBarNav.css";
import { TiDelete } from "react-icons/ti";


// const mapState = state => ({
//   loading: state.sideBar.loading
// });

// const actions = {
//   loadPerson
// };

const SidebarNav = ({
                      sideBarNavClickHandler,
                      person,
                      logOutClickHandler,
                      benefitsClickHandler,
                      faqClickHandler,
                      contactBabyYuYumClickHandler,
                      homePageClickHandler,
                      myProfileClickHandler,
                      usefulFormsClickHandler
                    }) => {
  if (person === null) {
    return <Fragment/>;
  }

  return (
    <Fragment>
      <div className="backdrop"/>
      <nav className="side-drawer">
        <TiDelete className="closeButton" onClick={ sideBarNavClickHandler } color ="red"/>
        <ul>
          <li onClick={ homePageClickHandler }>Home</li>
          <li onClick={ myProfileClickHandler }>My Profile</li>
          <li onClick={ benefitsClickHandler }>Benefits</li>
          <li onClick={ faqClickHandler }>FAQ's</li>
          <li onClick={ contactBabyYuYumClickHandler }>Contact Us</li>
          <li onClick={ usefulFormsClickHandler }>Useful Forms</li>
          <li onClick={ logOutClickHandler }>Logout</li>
        </ul>
      </nav>
    </Fragment>
  );
};

export default SidebarNav;
