import React from "react";
import {
	Container,
	Grid,
	List,
	Segment,
	Divider,
	Header,
	Image,
} from "semantic-ui-react";
import { withRouter } from "react-router";
import { compose } from "redux";

const Footer = ({ termsConditionsClickHandler }) => (
	<Segment
		className='byy-lighterblue'
		inverted
		vertical
		style={{ margin: "15em 0em 0em" }}
	>
		<Container textAlign='center'>
			<Grid divided inverted stackable>
				<Grid.Column width={5}>
					<List link inverted>
						<List.Item
							as='p'
							className='byy-text-darkblue'
							style={{ fontWeight: "bold", opacity: 1.0, padding: "5px" }}
						>
							Momentum: BabyYumYum 2022
						</List.Item>
					</List>
				</Grid.Column>
				<Grid.Column width={6}>
					<List>
						<List.Item
							as='p'
							className='byy-text-darkblue'
							style={{ fontWeight: "bold", opacity: 1.0, padding: "5px" }}
						>
							<a
								onClick={termsConditionsClickHandler}
								style={{ color: "#e01c21" }}
							>
								Terms &amp; Conditions
							</a>
						</List.Item>
					</List>
				</Grid.Column>
				<Grid.Column width={5}>
					<List>
						<List.Item
							className='byy-text-darkblue'
							style={{ fontWeight: "bold", opacity: 1.0, padding: "5px" }}
						>
							Call us:{" "}
							<a
								href='tel:087 897 0359'
								style={{ color: "#e01c21", fontWeight: "bold" }}
							>
								(087) 897-0359
							</a>
						</List.Item>
					</List>
				</Grid.Column>
			</Grid>
		</Container>
	</Segment>
);

export default compose(withRouter)(Footer);
