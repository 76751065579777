import React, {Component} from 'react';
import { compose } from "redux";
import { connect } from "react-redux";
import { reduxForm, Field } from "redux-form";
import TextInput from "../../../app/common/form/TextInput";
import { Segment, Grid } from "semantic-ui-react";
import DateInputMyProfile from "../../../app/common/form/DateInputMyProfile";
import {
  loadPerson,
  savePerson
} from "../../../features/nav/Sidebar/data/sideBarActions";
import SwagBagAccordion from "../../Accordions/SwagBagAccordion";

const mapState = state => ({
  loading: state.sideBar.loading,
  person: state.sideBar.person,
  initialValues: state.sideBar.person
});

const actions = {
  loadPerson,
};


class DeleveryAddressModal extends Component{
  render(){
    const editable = false;
    return(
      <div className="mainDivMyProfile">
       <Segment attached>
        <p className="title">
          Delivery Address For MyMomentum BabyYumYum Swag Bag
        </p>
        <h3 className="byy-text-pink"> (Applicable to Summit, Extender & Incentive scheme options from week 20 of pregnancy)</h3>
        <div style={{ textAlign: "left" }}>
          <SwagBagAccordion />
        </div>
        <br />
        <h4 className="byy-text-pink">
          Please provide the daytime address for the courier delivery
        </h4>
        <Grid stackable>
          <Grid.Row columns={2}>
            <Grid.Column>
              <Field
                name="DeliveryAddressLine1"
                component={TextInput}
                label="Number and Street"
                setFocus={editable}
                // disabled={!editable}
              />
            </Grid.Column>

            <Grid.Column>
              <Field
                name="DeliveryAddressLine2"
                component={TextInput}
                label="Suburb"
                // disabled={!editable}
              />
            </Grid.Column>

            <Grid.Column>
              <Field
                name="DeliveryCity"
                component={TextInput}
                label="City/Town"
                // disabled={!editable}
              />
            </Grid.Column>

            <Grid.Column>
              <Field
                name="DeliveryProvince"
                component={TextInput}
                label="Province"
                // disabled={!editable}
              />
            </Grid.Column>

            <Grid.Column>
              <Field
                name="DeliveryPostalCode"
                component={TextInput}
                label="Postal Code"
                // disabled={!editable}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    </div>
    )
  }
}

export default compose(
   connect(mapState, actions),
  // reduxForm({
  //   form: "PersonalInformationModal",
  //   enableReinitialize: true,
  //   destroyOnUnmount: false
  // })
)(DeleveryAddressModal);