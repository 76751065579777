import classnames from "classnames";
import React, { Component } from 'react';
import './scrollTop.css'
import { IoIosArrowDropup, IoIosArrowDropdown } from "react-icons/io";


class ScrollTop extends Component {
  constructor(props) {
    super(props);
    // this.state={is_visible:false};

    this.state = {
      prevScrollpos: window.pageYOffset ,
      visible: false,
    };

    this.baseState = this.visible;
  }


  componentDidMount() {
    /*var scrollComponent = this;
    document.addEventListener("scroll", function(e){
        scrollComponent.toggleVisibility();
    });*/
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll = () => {
    const { prevScrollpos } = this.state;
    let showing;
    const currentScrollPos = window.pageYOffset;
    let visible = null;
    if (prevScrollpos > currentScrollPos) {
      clearInterval(showing);
      visible = prevScrollpos > currentScrollPos;
      clearInterval(showing);
    } else if (prevScrollpos < currentScrollPos) {
      clearInterval(showing);
      visible = prevScrollpos < currentScrollPos;
      clearInterval(showing);
    }

    this.setState({
      prevScrollpos: currentScrollPos,
      visible
    });

    showing = setInterval(() => {
      this.setState({
        time: this.state.time + 7,
        prevScrollpos: currentScrollPos,
        visible: false
      })
      clearInterval();
    }, 7000);
    
  };

  scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }

  scrollToButtom() {
    window.scrollTo({
      top: 100000000,
      behavior: "smooth"
    });
  }

  render() {
    return (
      <div className={ classnames("updown", {
        "navbar--hidden": !this.state.visible
      }) }>
        <div className="scrollToTop" onClick={ () => this.scrollToTop() }>
          <IoIosArrowDropup/>
        </div>
        <div className="scrollToBottum" onClick={ () => this.scrollToButtom() }>
          <IoIosArrowDropdown/>
        </div>
      </div>
    )
  }
}

export default ScrollTop;








