import React from 'react';
import './contactBabyYumYum.css'
import FullBanner from '../pageContent/FullBanner';
import ScrollTop from '../scrollTop/ScrollTop'


const ContactBabyYumYum = () => {
  let ussd = "tel:" + encodeURI("*") + "134" + encodeURI("*") + "677" + encodeURI("#");
  return (
    <div className='main'>
      <div className="contactBabyBanner">
        <FullBanner/>
      </div>

      <div className="mainDif">
        <p className="title">Important Contact Details</p>

        <div className="leftInfo">
          <p>
            <b>Momentum Health</b><br/>
            Momentum Health<br/>
            Call Centre: <a name="format-detection" content="telephone=no" href="tel:0860117959">086 011 7859</a><br/>
            Momentum Health Queries Email:<br/>
            <a href="mailto:member@momentumhealth.co.za">member@momentumhealth.co.za</a><br/>
            Momentum Health Claims Email:<br/>
            <a href="mailto:claims@momentumhealth.co.za">claims@momentumhealth.co.za</a><br/>
            Momentum Health Website:<br/>
            <a href="www.momentumhealth.co.za">momentumhealth.co.za</a>
          </p>

          <p>
            <b>BabyYumYum</b><br/>
            BabyYumYum Call Centre:<br/>
            <a href="tel:0878970359">087 897 0359</a><br/>
            BabyYumYum Email:<br/>
            <a href="mailto:momentum@babyyumyum.co.za">momentum@babyyumyum.co.za</a><br/>
            Momentum BabyYumYum Portal:<br/>
            <a href="www.momentum.babyyumyum.co.za">momentum.babyyumyum.co.za</a><br/>
          </p>
        </div>
      </div>
      <ScrollTop/>
    </div>
  )
};

export default ContactBabyYumYum;