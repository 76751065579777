import React, { Component } from "react";
import { Grid, Form, Button, Radio } from "semantic-ui-react";
import "./Popup.css";
import { isMobileView } from "../../app/common/utils/common";
import DateInput from "../../app/common/form/DateInput";
import { Field } from "redux-form";
import { reduxForm } from "redux-form";
import { compose } from "redux";
import { loadPerson } from "../nav/Sidebar/data/sideBarActions";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { submitDates } from "../verifyDueDate/data/submitNewDateAction";
import { loadQuestion, } from "../progressiveProfiling/data/progressiveProfilingQuestionsActions";
import { combineValidators, isRequired } from "revalidate";


const mapState = state => ({
  person: state.sideBar.person,
  questions: state.progressiveProfiling.questions
});

const actions = {
  loadPerson,
  submitDates,
  loadQuestion
};

export const validate = combineValidators({
  edd: isRequired({ message: "Estimated Due Date is required" }),
  //dob: isRequired({ message: 'Date of Birth is required' })
});
class PopupEDD extends Component {
  constructor(props) {
    super(props);
    this.state = {
      edd: null,
      eddv: null,
      visible: true,
      showBD: false

    };

    this.handleChange = this.handleChange.bind(this);
  }


  componentDidMount() {
    this.props.loadPerson();
  }

  handleSubmitDates = async value => {
    await this.props.loadQuestion(localStorage.getItem("BEDD"));
    const { person, questions } = this.props;
    let sendValues;
    if (this.state.showBD) {
      sendValues = {
        EstimatedDeliveryDate: value.edd,
        EstimatedBirthDate: value.bd
      };
    }
    if (!this.state.showBD) {
      sendValues = {
        EstimatedDeliveryDate: value.edd,
        EstimatedBirthDate: value.edd
      };
    }
    try {
      this.setState({ submitting: true });
      await this.props.submitDates(sendValues);
      localStorage.setItem('EDD', person.EstimatedDeliveryDate);
      if (questions.ProfilingQuestions.length >= 0){
        this.props.history.push(this.props.history.push(`/content/${person.ContentPageID}`));
      }
      else{
        this.props.history.push("/progressiveProfiling");
      }
      
    } catch {
    } finally {
      this.setState({ submitting: false });
    }
  };

  handleChange = (e, { value }) => this.setState({ value });

  yesRadioClickHandler = () => {
    this.setState({ showBD: true });
    this.setState({ value: false })
  };

  noRadioClickHandler = () => {
    this.setState({ showBD: false });
    this.setState(prevState => {
      return { value: !prevState.value }
    });
  };

  render() {
    const { handleSubmit, submitting } = this.props;

    
    return (
      <div>
        <div className="backdropUpdateDueDate"/>
        <div
          className="Popup"
          style={ isMobileView() ? { left: "5%", top: "25%", width: 300 } : null }
        >
          <div className="topBarUpdateDueDate">
            <h2 className="byy-text-darkblue">Update your Due Date</h2>
          </div>
          <Grid textAlign="center" verticalAlign="middle" stackable>
            <Grid.Row>
              <Grid.Column>
                <Form
                  onSubmit={ handleSubmit(this.handleSubmitDates) }
                  className="byy-text-darkblue"
                  style={ {
                    padding: "20px",
                    textAlign: "center",
                    fontWeight: "bold",
                    margin: "2"
                  } }
                >
                  <div className="content">
                    Please confirm your Expected Birth Date and Expected Due
                    Date
                  </div>
                  <br/>
                  <div>
                    <label>Full-Term Estimated Due Date is</label>
                    <Field
                      name="edd"
                      component={ DateInput }
                      dateFormat={ "dd LLL yyyy" }
                      setFocus
                      minDate={ new Date().setFullYear(new Date().getFullYear()) }
                      maxDate={ new Date().setFullYear(
                        new Date().getFullYear() + 1
                      ) }
                    />
                  </div>
                  <br/>

                  <p>
                    Baby may be born before or after this due date. Has your
                    medical adviser confirmed a birth day different to your due
                    date.
                  </p>
                  <Form.Field>
                    <Radio
                      label="Yes"
                      name="radioGroup"
                      value="yes"
                      checked={ this.state.value === "yes" }
                      onClick={ this.yesRadioClickHandler }
                      onChange={ this.handleChange }
                    />
                  </Form.Field>
                  <Form.Field>
                    <Radio
                      label="No"
                      name="radioGroup"
                      value="no"
                      checked={ this.state.value === "no" }
                      onClick={ this.noRadioClickHandler }
                      onChange={ this.handleChange }
                    />
                  </Form.Field>

                  <div>
                    { this.state.showBD &&
                    <div>
                      <label>Baby is expected to be born on</label>

                      <Field
                        name="bd"
                        component={ DateInput }
                        dateFormat={ "dd LLL yyyy" }
                        minDate={ new Date().setFullYear(new Date().getFullYear()) }
                        maxDate={ new Date().setFullYear(
                          new Date().getFullYear() + 1
                        ) }
                      />
                    </div>
                    }

                  </div>
                  <Grid.Column floated="left" width={ 6 }>
                    <Button
                      style={ { marginTop: 20, marginLeft: 0 } }
                      className="ui dark blue button"
                      content="Cancel"
                      onClick={ this.props.submitClickHandler }
  
                    />
                  </Grid.Column>
                  <Grid.Column floated="right" width={ 5 }>
                    <Button
                      style={ { marginTop: 20, marginLeft: 0 } }
                      className="ui red"
                      content="Save"
                      loading={ submitting }
                      disabled={ submitting }
                      //onClick={this.handleSubmitDates}
                    /> 
                  </Grid.Column>
                </Form>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row/>
          </Grid>
        </div>
      </div>
    );
  }
}

export default compose(
  withRouter,
  connect(mapState, actions),
  reduxForm({
    form: "PopupEDD",
    validate,
    enableReinitialize: true,
    destroyOnUnmount: false
  })
)(PopupEDD);
