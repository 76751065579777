import { createReducer } from '../../app/store/createReducer';
import {
  AUTHUSER_LOAD,
  AUTHUSER_CLEAR,
  AUTHUSER_SETPHOTO,
  AUTHUSER_LOADING,
  AUTHUSER_LOADING_COMPLETED,
  AUTHUSER_SUBMITTING,
  AUTHUSER_SUBMITTING_COMPLETED,
  AUTHUSER_LOAD_PUSHNOTIFICATION
} from './authUserConstants';


function getInitialState() {
  return {
    loading: false,
    submitting: false,
    user: {
      DisplayName: null,
      PhotoURL: null
    },
    tutors: [],
    groups: []
  };
}

const initialState = getInitialState();

const loading = (state, payload) => {
  return {
    ...state,
    loading: true
  };
};

const loadingCompleted = (state, payload) => {
  return {
    ...state,
    loading: false
  };
};

const submitting = (state, payload) => {
  return {
    ...state,
    submitting: true
  };
};

const submittingCompleted = (state, payload) => {
  return {
    ...state,
    submitting: false
  };
};

const loadAuthUser = (state, payload) => {
  return {
    ...state,
    user: payload.Details
  };
};

const clearAuthUser = (state, payload) => {
  return getInitialState();
};

const setAuthPhoto = (state, payload) => {
  var user = state.user;
  user.PhotoURL = payload.photoURL;

  return {
    ...state,
    user
  };
};


const loadPushNotification = (state, payload) => {
  return {
    ...state,
    pushNotification: payload.pushNotification
  }
};

export default createReducer(initialState, {
  [AUTHUSER_LOADING]: loading,
  [AUTHUSER_LOADING_COMPLETED]: loadingCompleted,
  [AUTHUSER_SUBMITTING]: submitting,
  [AUTHUSER_SUBMITTING_COMPLETED]: submittingCompleted,
  [AUTHUSER_LOAD]: loadAuthUser,
  [AUTHUSER_CLEAR]: clearAuthUser,
  [AUTHUSER_SETPHOTO]: setAuthPhoto,
  [AUTHUSER_LOAD_PUSHNOTIFICATION]: loadPushNotification
});
