import React, { Component, Fragment } from "react";
import { Container, Icon, Image } from "semantic-ui-react";
import { openModal } from "../../../app/modals/modalActions";
import { compose } from "redux";
import { connect } from "react-redux";
import "./homePage.css";
import { Helmet } from "react-helmet";
import LoginForm from "../loginModal/LoginForm";
import { isMobileView } from "../../../app/common/utils/common";
import FullBanner from "../../pageContent/FullBanner";

const actions = {
	openModal,
};

class HomePage extends Component {
	async componentDidMount() {
		// if (isLoggedIn()) {
		//   const role = getRole();
		//   this.props.history.push(`/${role.toLowerCase()}`);
		// }
	}

	handleShowLogin = () => {
		this.props.openModal("LoginModal", { hasLoggedIn: this.handleLoggedIn });
	};

	// handleLoggedIn = () => {
	//   const role = getRole();
	//   this.props.history.push(`/${role.toLowerCase()}`);
	// };

	render() {
		return (
			<Fragment>
				<Helmet>
					<title>BabyYumYum - Login</title>
				</Helmet>
				<div className="homepage">
					<Container>
						<div className="fullBannerHomePage">
							<FullBanner />
							<br />
						</div>
						<div
							className="main-background"
							style={{ backgroundImage: "url('../assets/loginpagebaby1.jpg')" }}
						>
							<LoginForm />
							<div
								className={"welcome"}
								style={isMobileView() ? { position: "unset" } : null}
							>
								<div
									style={isMobileView() ? { paddingTop: 400, paddingLeft: 20 } : null}
								>
									<p className="textWelcome">
										Welcome to the MyMomentum BabyYumYum maternity benefits portal – the
										exclusive home to Momentum maternity members.
										<br />
										We look forward to being with you one baby step at a time.
									</p>
									<p className="textWelcome">
										<img src="../assets/whatsapp.png" /> For a summary of your baby's
										development and other juicy tidbits, <b>WhatsApp</b> our{" "}
										<b>MommyBot</b> on{" "}
										<a href="tel:087 153 1024">
											<b>087 153 1024</b>
										</a>{" "}
										and say Hi!
									</p>

									<p className="textWelcome" style={{ color: "#ff66c4" }}>
										Your health and safety are always our main concern, so we would like
										to reassure you that, together with our partners, we have implemented
										precautionary procedures for eligible swag bag deliveries and any
										other interactions we may have with you.
									</p>

									<p className="textWelcome" style={{ color: "#ff66c4" }}>
										Please log in and read through our FAQ section for answers to your
										questions.
									</p>

									<p className="textWelcome">
										Once you have given birth, and you wish to book your nurse visit,
										please contact Momentum Wellness by emailing
										<a
											href="mailto:nursevisit@momentum.co.za"
											style={{ color: "#e01c21" }}
										>
											{" "}
											nursevisit@momentum.co.za
										</a>{" "}
										or calling
										<a href="tel:0860 117 859" style={{ color: "#e01c21" }}>
											{" "}
											0860 117 859
										</a>
									</p>

									<p
										className="textWelcome"
										style={{ color: "#ff66c4", marginBottom: 100 }}
									>
										<Icon name="heart" color="red" />
										the MyMomentumBabyYumYum team.
									</p>
								</div>
							</div>
						</div>
						{isMobileView && (
							<div>
								<br />
								<br />
								<br />
								<br />
								<br />
								<br />
								<br />
								<br />
								<br />
								<br />
								<br />
								<br />
								<br />
								<br />
								<br />
								<br />
							</div>
						)}
					</Container>
				</div>
			</Fragment>
		);
	}
}

export default compose(connect(null, actions))(HomePage);
