import {
  PAGECONTENT_LOADING,
  PAGECONTENT_LOADING_COMPLETED,
  LOAD_PAGECONTENT,
  PAGECONTENT_DETAIL_LOADING,
  LOAD_PAGECONTENT_DETAIL,
  PAGECONTENT_DETAIL_LOADING_COMPLETED
} from './pageContentConstants';
import { httpGet } from '../../../app/common/utils/http';
import { toastr } from 'react-redux-toastr';


export const loadPageContent = contentPageID => async (dispatch, getState) => {

  if (!getState().pageContent.content.some(c => c.contentPageID === contentPageID)) {
    
    await dispatch({ type: PAGECONTENT_LOADING });
    
    try {
      const content = await httpGet(
        `Content/Get?ContentPageID=${ contentPageID }&ForHTML=true`, true, true
      );
      await dispatch({
        type: LOAD_PAGECONTENT,
        payload: {
          contentPageID,
          content
        }
      });
    } catch (error) {
      toastr.error('Error', 'There was a problem getting the content');
      throw error;
    } finally {
      await dispatch({ type: PAGECONTENT_LOADING_COMPLETED });
    }
  }
};


export const loadPageContentDetail = contentPageID => async (dispatch, getState) => {
  await dispatch({ type: PAGECONTENT_DETAIL_LOADING });
  try {
    const contentDetail = await httpGet(
      `Content/GetContentPageIDFromContentID?ContentPageID=${ contentPageID }`
    );
    await dispatch({
      type: LOAD_PAGECONTENT_DETAIL,
      payload: {
        contentPageID,
        contentDetail
      }
    });
  } catch (error) {
    toastr.error('Error', 'There was a problem getting the content');
    throw error;
  } finally {
    await dispatch({ type: PAGECONTENT_DETAIL_LOADING_COMPLETED });
  }

};