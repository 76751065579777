import React, { useState } from "react";
import { Field } from "redux-form";
import { Grid, Segment, Radio } from "semantic-ui-react";
import PhoneNumberInputMyProfile from "../../app/common/form/PhoneNumberInputMyProfile";
import TextInput from "../../app/common/form/TextInput";
import DateInputMyProfile from "../../app/common/form/DateInputMyProfile";
import { combineValidators, isRequired } from "revalidate";
import "./MyProfile.css";
import BirthingBuddyAccordion from "../Accordions/BirthingBuddyAccordion";
import SwagBagAccordion from "../Accordions/SwagBagAccordion";
import RadioInput from "../../app/common/form/RadioInput";
import CheackBoxInput from "../../app/common/form/CheackBoxInput";

export const validate = combineValidators({
  FirstName: isRequired({ message: "First Names is required" }),
  LastName: isRequired({ message: "Last Name is required" }),
  EDD: isRequired({ message: "Estimated Delivery Date is required" })
});

 const MyProfileFields = ({ person, initialValues, hasBaby }) => {
  const [user] = useState(initialValues ? initialValues.FirstName : null);
  // let checkedIsBabyBorn = useState(person.isBabyBornYN);

  const editable = false;

  return (
    <div className="mainDivMyProfile">
      <Segment attached warapped>
        <p className="title">My Personal Information</p>
        <Grid stackable>
          <Grid.Row columns={2}>
            <Grid.Column width={8}>
              <Field
                name="FirstName"
                component={TextInput}
                label="First Name"
                setFocus={editable}
                disabled={!editable}
              />
            </Grid.Column>

            <Grid.Column width={8}>
              <Field
                name="LastName"
                component={TextInput}
                label="Last Name"
                disabled={!editable}
              />
            </Grid.Column>

            <Grid.Column width={8}>
              <Field
                name="DOB"
                component={DateInputMyProfile}
                label="Date of Birth"
                disabled={!editable}
                dateFormat={"dd LLL yyyy"}
                minDate={new Date().setFullYear(new Date().getFullYear() - 100)}
                maxDate={new Date().setFullYear(new Date().getFullYear() - 10)}
              />
            </Grid.Column>

            <Grid.Column width={8}>
              <Field
                name="EstimatedDeliveryDate"
                component={DateInputMyProfile}
                label="Due Date"
                dateFormat={"dd LLL yyyy"}
                minDate={new Date().setFullYear(new Date().getFullYear())}
                maxDate={new Date().setFullYear(new Date().getFullYear() + 1)}
                //disabled={!editable}
              />
            </Grid.Column>

            <Grid.Column>
              <Field
                name="SchemeOption"
                component={TextInput}
                label="Medical Scheme Option"
                disabled={!editable}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
      <br />

      <Segment attached warapped>
        <p className="title">Our new bundle of joy</p>

        <Grid stackable>
          <div style={{ textAlign: "left" }}>
            <h4 className="byy-text-pink">Has baby been born?</h4>
            <Field
              name="isBabyBornYN"
              component={RadioInput}
              labelLeft="No"
              labelRight="Yes"
              setFocus={editable}
              toggle={true}
              checked={this.state.hasBaby}
              onChange={ this.handleChangeBabyBorn }
         
            />
          </div>
          {hasBaby && (
            <Grid.Row columns={2}>
              <Grid.Column width={8}>
                <Grid>
                  <Grid.Column>
                    <h4>Gender</h4>
                  </Grid.Column>
                  <Grid.Column width={8}>
                    <Field
                      name="IsBoysYN"
                      component={CheackBoxInput}
                      label="Boy(s)"
                      setFocus={editable}
                      //  disabled={!editable}
                    />
                    <Field
                      name="IsGirlsYN"
                      component={CheackBoxInput}
                      label="Girl(s)"
                      setFocus={editable}
                      //  disabled={!editable}
                    />
                  </Grid.Column>
                </Grid>
              </Grid.Column>

              <Grid.Column width={8}>
                <Grid>
                  <Grid.Column width={4}>
                    <h4>Delivery was...</h4>
                  </Grid.Column>
                  <Grid.Column width={6}>
                    <Field
                      name="birthInput"
                      component={RadioInput}
                      label="Natural birth"
                      setFocus={editable}
                      //  disabled={!editable}
                    />
                    <Field
                      name="birthInput"
                      component={RadioInput}
                      label="Cesarean section"
                      setFocus={editable}

                      //  disabled={!editable}
                    />
                  </Grid.Column>
                </Grid>
              </Grid.Column>

              <Grid.Column width={4}>
                <Field
                  name="BabyBornOn"
                  component={DateInputMyProfile}
                  label="Baby born on"
                  // setFocus={editable}
                  // disabled={!editable}
                />
              </Grid.Column>
            </Grid.Row>
          )}
        </Grid>
      </Segment>
      <br />
      <Segment attached>
        <p className="title">
          Delivery Address For MyMomentum BabyYumYum Swag Bag
        </p>
        <div style={{ textAlign: "left" }}>
          <SwagBagAccordion />
        </div>
        <br />
        <h4 className="byy-text-pink">
          Please provide the daytime address for the courier delivery
        </h4>
        <Grid stackable>
          <Grid.Row columns={2}>
            <Grid.Column>
              <Field
                name="DeliveryAddressLine1"
                component={TextInput}
                label="Number and Street"
                setFocus={editable}
                // disabled={!editable}
              />
            </Grid.Column>

            <Grid.Column>
              <Field
                name="DeliveryAddressLine2"
                component={TextInput}
                label="Suburb"
                // disabled={!editable}
              />
            </Grid.Column>

            <Grid.Column>
              <Field
                name="DeliveryCity"
                component={TextInput}
                label="City/Town"
                // disabled={!editable}
              />
            </Grid.Column>

            <Grid.Column>
              <Field
                name="DeliveryProvince"
                component={TextInput}
                label="Province"
                // disabled={!editable}
              />
            </Grid.Column>

            <Grid.Column>
              <Field
                name="DeliveryPostalCode"
                component={TextInput}
                label="Postal Code"
                // disabled={!editable}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
      <br />

      <Segment attached>
        <p className="title"> Birthing Buddy Information</p>
        <div style={{ textAlign: "left" }}>
          <BirthingBuddyAccordion />
        </div>
        <br />
        <h4 className="byy-text-pink">
        I have asked the following person to be my birthing buddy and they are happy 
        for me to submit their personal information to BabyYumYum for this maternity programme.<br/>
          Don't worry if you don't have this info now, you can always come back
          later to fill it in
        </h4>
        <Grid stackable>
          <Grid.Row columns={2}>
            <Grid.Column>
              <Field
                name="BirthingBuddyName"
                component={TextInput}
                label="First Name"
                setFocus={editable}
                // disabled={!editable}
              />
            </Grid.Column>

            <Grid.Column>
              <Field
                name="BirthingBuddySurname"
                component={TextInput}
                label="Last Name"
                // disabled={!editable}
              />
            </Grid.Column>

            <Grid.Column>
              <Field
                name="BirthingBuddyMobileNum"
                component={PhoneNumberInputMyProfile}
                label="Cell Number"
                // disabled={!editable}
              />
            </Grid.Column>

            <Grid.Column>
              <Field
                name="BirthingBuddyDOB"
                component={DateInputMyProfile}
                label="Date of Birth"
                // disabled={!editable}
                dateFormat={"dd LLL yyyy"}
                minDate={new Date().setFullYear(new Date().getFullYear() - 100)}
                maxDate={new Date().setFullYear(new Date().getFullYear() - 10)}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
      <br />
    </div>
  );
};

export default MyProfileFields;
