import { createReducer } from '../../app/store/createReducer'
import {
  AUTH_LOADING,
  AUTH_LOADING_COMPLETED,
  AUTH_RESET,
  LOGIN,
  LOAD_VERSION
} from "./authConstants";


const getInitialState = () => {
  return {
    loading: false,
    pushNotification: null,
    version: null
  }
};

const initialState = {
  ...getInitialState(),
  loggedIn: false
};

const loading = (state, payload) => {
  return {
    ...state,
    loading: true,
    loadingName: null
  };
};

const loadingCompleted = (state) => {
  return {
    ...state,
    laoding: false,
    laodingName: null
  };
};

const reset = () => {
  return {
    ...getInitialState()
  };
};

const login = (state) => {
  return {
    ...state,
    loggedIn: true
  };
};

const loadVersion = (state, payload) => {
  return {
    ...state,
    version: payload.version
  };
};

export default createReducer(initialState, {
  [AUTH_LOADING]: loading,
  [AUTH_LOADING_COMPLETED]: loadingCompleted,
  [AUTH_RESET]: reset,
  [LOGIN]: login,
  [LOAD_VERSION]: loadVersion
});