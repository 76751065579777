import React from "react";
import "./TermsConditionsPage.css";
import FullBanner from "../pageContent/FullBanner";
import ScrollTop from "../scrollTop/ScrollTop";
import { Accordion, Icon } from "semantic-ui-react";

const PrivacyPolicyText = () => {
  return (
    <div>
      <p className="subHeadingTermsConditions">
        What Does This Privacy Policy Apply To?
      </p>
      <p>
        This Privacy Policy applies to personal information collected by
        BabyYumYum in connection with the services they offer. Find out more
        about BabyYumYum at{" "}
        <a
          href="https://babyyumyum.co.za/"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://babyyumyum.co.za/
        </a>
        . This includes information collected offline through our helpline,
        direct marketing campaigns, surveys and competitions, and online through
        our websites, branded pages on third party platforms and applications
        accessed or used through such websites or third-party platforms which
        are operated by or on behalf of BabyYumYum. This Privacy Policy is
        hereby incorporated into and forms part of the terms and conditions of
        use of the applicable site. For information about the company
        responsible for protecting your personal information, see Your privacy
        rights and who to contact below.
        <br />
        <br />
        This Privacy Policy does not apply to:
        <p className="bulletPointsTermsConditions">
          • information collected by third party websites, platforms and/or
          applications (“Third Party Sites”) which we do not control;
          <br />
          • information collected by Third Party Sites which you access via
          links on BabyYumYum sites; or
          <br />
          • banners, surveys and other advertisements or promotions on Third
          Party Sites that we may sponsor or participate in.
          <br />• These Third Party Sites may have their own privacy policies
          and terms and conditions. We encourage you to read them before using
          those Third Party Sites.
        </p>
        <br />
        All access is subject to the approval of BabyYumYum and Momentum in its
        discretion. BabyYumYum will be entitled to take steps to restrict or
        suspend the services to a User who misuses the MyMomentum BabyYumYum
        services.
        <br />
      </p>
      <p className="subHeadingTermsConditions">Your Consent</p>
      <p>
        BabyYumYum will not collect, use or disclose your personal information
        without your consent. In most cases, we will ask for your consent
        explicitly but, in some cases, we may infer consent from your actions
        and behaviour. By using our sites, you are consenting to the BabyYumYum
        company collecting, using and disclosing your personal information in
        accordance with this Privacy Policy.
      </p>
      <p>
        We may ask you to provide additional consent if we need to use your
        personal information for purposes not covered by this Privacy Policy.
        You are not obliged to provide such consent but if you decide not to
        then your participation in certain activities may be restricted. If you
        provide additional consent, the terms of that consent shall prevail in
        the event of any conflict with the terms of this Privacy Policy.
      </p>
      <p>
        If you do not agree to the collection, use and disclosure of your
        personal information in this way, please do not use the BabyYumYum Sites
        or otherwise provide BabyYumYum with personal information.
        <br />
      </p>
      <p className="subHeadingTermsConditions">Children</p>
      <p>
        The majority of BabyYumYum Sites are designed and intended for use by
        adults. Where a BabyYumYum Site is intended for use by a younger
        audience, we will obtain consent from a parent or guardian before we
        collect personal information where we feel it is appropriate to do so or
        where it is required by applicable data protection laws and regulations
        (the age at which consent is necessary varies from country to country).
        If you are a child over the age where parental consent is required in
        your country, you should review the terms of this Privacy Policy with
        your parent or guardian to make sure you understand and accept them.
      </p>
      <p>
        If we discover that we have collected information without consent from a
        parent or guardian where such consent should have been obtained, we will
        delete that information as soon as possible.
      </p>
      <p>
        Access to certain parts of the BabyYumYum Sites and/or eligibility to
        receive prizes, samples or other rewards may be limited to users
        over/under a certain age. We may use your personal information to carry
        out age verification checks and enforce any such age restrictions.
        <br />
      </p>
      <p className="subHeadingTermsConditions">
        What Information Do We Collect?
      </p>
      <p>
        In this Privacy Policy, your "personal information" means information or
        pieces of information that could allow you to be identified. This
        typically includes information such as your name, address, username,
        picture, email address, and contact numbers, but can also include other
        information such as IP address, shopping habits, preferences and
        information about your lifestyle or preferences such as your hobbies and
        interests.
      </p>
      <p>
        We may collect personal information about you from different sources,
        including (but not limited to):
        <div className="bulletPointsTermsConditions">
          • Information you give us directly
        </div>
      </p>
      <p>
        We may collect information from you directly when you provide us with
        personal information, e.g. when you register for prize draws or
        competitions, sign up to receive information, use applications, buy a
        product or service from us, fill out a survey, or make a comment or
        enquiry.
      </p>
      <p>
        The types of information we may collect from you directly include your:
        <div className="bulletPointsTermsConditions">
          • full name
          <br />
          • address
          <br />
          • email address
          <br />
          • username
          <br />
          • telephone number
          <br />
          • age
          <br />
          • date of birth
          <br />
          • gender
          <br />
          • user-generated content, posts and other content you submit to
          BabyYumYum Sites
          <br />
          • any other person information you voluntarily provide to us
          <br />
        </div>
      </p>
      <p className="subHeadingTermsConditions">
        What Information Do We Collect?
      </p>
      <div>
        We use cookies and other tools (such as USSD, SMS and web analytic
        tools) to automatically collect information about you when you use
        BabyYumYum Sites, subject to the terms of this Privacy Policy and
        applicable data protection laws and regulations. The types of
        information we may collect automatically include:
      </div>
      <div className="bulletPointsTermsConditions">
        • information about the type of mobile devices, browsers and network
        service providers you use
        <br />
        • details of the pages you have viewed
        <br />
        • email address
        <br />
        • your IP address
        <br />
        • your contact number
        <br />
        • the hyperlinks you have clicked
        <br />
        • your username, profile picture, gender, networks and any other
        information you choose to share when using Third Party Sites (such as
        when you use the “Like” functionality on Facebook or the +1
        functionality on Google+)
        <br />
        • the websites you visited before arriving at a BabyYumYum Site
        <br />
      </div>
      <br />
      <p>
        <p className="subHeadingTermsConditions">
          Information we collect from other sources
        </p>
        <div>
          
          We may receive personal information about you from other sources if
          you have given permission for that information to be shared. This may
          include information from commercially available sources, such as
          public databases and data aggregators, and information from third
          parties. The types of personal information that we may collect from
          such sources include, but are not limited to your:
        </div>
        <div className="bulletPointsTermsConditions">
          • name
          <br />
          • street address
          <br />
          • age
          <br />
          • your IP address
          <br />
          • your shopping habits
          <br />
          • preferences and information about your lifestyle such as your
          hobbies and interests
          <br />
          • publicly available information such as user-generated content, blogs
          and postings, as permitted by law
          <br />
        </div>
      </p>

      <p className="subHeadingTermsConditions">
        How Do We Use Your Personal Information?
      </p>
      <p>
        We may use your personal information:
        <div className="bulletPointsTermsConditions">
          • to improve our products and your experience on the BabyYumYum Sites.
        </div>
      </p>
      <p>
        We may use your personal information to:
        <div className="bulletPointsTermsConditions">
          • evaluate the use of the BabyYumYum Sites, products and services
          <br />
          • street address
          <br />
          • analyse the effectiveness of our advertisements, competitions and
          promotions
          <br />
          • personalise your user experience, as well as to evaluate
          (anonymously and in the aggregate) statistics on user activity, such
          as what time you entered a competition, whether you’ve entered it
          before and what site referred you to it
          <br />
          • make the BabyYumYum Sites easier to use and to better tailor the
          BabyYumYum Sites and our products to your interests and needs
          <br />
          • help speed up your future activities and experience on BabyYumYum
          Sites. For example, a site can recognise that you have provided your
          personal information and will not request the same information a
          second time
          <br />
          • collect information about the device you are using to view the
          BabyYumYum Site, such as your IP address or the type of Internet
          browser, operating system or mobile device you are using, and link
          this to your personal information so as to ensure that each BabyYumYum
          Site presents the best user experience for you
          <br />
          • to contact you regarding products and services which may be of
          interest to you, provided you have given us consent to do so or you
          have previously requested a product or service from us and the
          communication is relevant or related to that prior request and made
          within any timeframes established by applicable laws
          <br />
        </div>
      </p>
      <p>
        We may use your personal information to:
        <div className="bulletPointsTermsConditions">
          • suggest products or services (including those of relevant third
          parties) which we think may be of interest to you
          <br />
          • offer you the opportunity to take part in competitions or promotions
          <br />
          • to provide you with products or services that you request from us
          <br />
        </div>
      </p>
      <p>
        You can opt out of receiving communications from us at any time. Any
        direct marketing communications that we send to you will provide you
        with the information and means necessary to opt out.
      </p>
      <p>
        We may use your personal information:
        <div className="bulletPointsTermsConditions">
          • for the purposes of competitions or promotions that you have entered
          <br />
          • to send you information, products or samples that you have requested
          <br />
          • to respond to your queries or comments
          <br />
        </div>
      </p>
      <p>
        Where we collect personal information for a specific purpose, we will
        not keep it for longer than is necessary to fulfil that purpose, unless
        we have to keep it for legitimate business or legal reasons. In order to
        protect information from accidental or malicious destruction, when we
        delete information from our services we may not immediately delete
        residual copies from our servers or remove information from our backup
        systems.
      </p>
      <p className="subHeadingTermsConditions">Message Services</p>
      <p>
        We may make available a service through which you can receive text or
        other types of messages from BabyYumYum (such as short message service,
        or SMS, enhanced message service, or EMS, multimedia message service, or
        MMS, and electronic mail, or eMail) on your wireless, mobile or desktop
        device (“Message Service”). Unless and until you have elected to opt out
        of receiving such messages by following the instructions in the Your
        privacy rights and who to contact section below.
      </p>
      <p>
        You understand that your carrier’s standard rates apply to these
        messages, and that you may change your mind at any time by following the
        instructions in the Your privacy rights and who to contact section
        below. If fees are charged to your carrier’s account invoice, you agree
        that we may collect from you and provide your carrier with your
        applicable payment information in connection therewith. You represent
        that you are the owner or authorized user of the device you use to sign
        up for the Message Service, and that you are authorized to approve the
        applicable charges. In addition to meeting certain age restrictions and
        any other terms and conditions associated with each Message Service, you
        may be required to register personal information such as your name, text
        message, wireless address, or mobile phone number. We may also obtain
        the date, time and content of your messages in the course of your use of
        the Message Service. We will use the information we obtain in connection
        with our Message Service in accordance with this Privacy Policy. Please
        note, however, that your wireless carrier and other service providers
        may also collect data about your wireless device usage, and their
        practices are governed by their own policies.
      </p>
      <p>
        You acknowledge and agree that the Message Service is provided via
        wireless systems which use radios (and other means) to transmit
        communications over complex networks. We do not guarantee that your use
        of the Message Service will be private or secure, and we are not liable
        to you for any lack of privacy or security you may experience. You are
        fully responsible for taking precautions and providing security measures
        best suited for your situation and intended use of the Message Service.
        We may also access the content of your wireless and/or mobile phone
        account with your carrier for the purpose of identifying and resolving
        technical problems and/or service-related complaints.
        <br />
      </p>
      <p className="subHeadingTermsConditions">
        Who Do We Share Your Personal Information With?
      </p>
      <p>
        We may share your personal information with:
        <div className="bulletPointsTermsConditions">
          • our clients who have enlisted our information processing services on
          their behalf
          <br />
          • advertising, marketing and promotional agencies to help us deliver
          and analyse the effectiveness of our advertising campaigns and
          promotions
          <br />
          • third parties required to deliver a product or service to you, such
          as a delivery or postal service delivering a product that you have
          ordered or won
          <br />
          • law enforcement or government authorities where they have followed
          due legal process to request us to disclose the information
          <br />
          • third parties who wish to send you information about their products
          and services, but only if you have given us consent to do so
          <br />
          • third party providers of services, such as data processing, to
          BabyYumYum
          <br />
          • web analytics tool providers, such as Google
          <br />
        </div>
      </p>
      <p>
        We may also share your personal information with companies,
        organisations or individuals outside of BabyYumYum if we believe that
        disclosure of the information is necessary for legal reasons.
      </p>
      <p>
        We may share your personal information to:
        <div className="bulletPointsTermsConditions">
          • enforce applicable terms of use of BabyYumYum Sites
          <br />
          • conduct investigations into possible breaches of applicable laws
          <br />
          • detect, prevent and protect against fraud and any technical or
          security vulnerabilities
          <br />
          • comply with applicable laws and regulations, co-operate in any legal
          investigation and meet enforceable governmental requests
          <br />
        </div>
      </p>
      <p>
        If we do share your personal information with a third party, we shall
        use our best efforts to ensure that they keep your information secure,
        take all reasonable steps to protect it from misuse and only use it in a
        manner consistent with this Privacy Policy and applicable data
        protection laws and regulations. BabyYumYum does not sell personal
        information, except to an entity to which we divest all or a portion of
        our business, or otherwise in connection with a merger, consolidation,
        change in control, reorganization or liquidation of all or a portion of
        our business.
        <br />
      </p>
      <p className="subHeadingTermsConditions">
        Where Do We Store Your Personal Information?
      </p>
      <p>
        The main host servers for BabyYumYum are located in South Africa. Some
        host servers for BabyYumYum Sites in the European Economic Area are
        located in Germany and the UK. Some host servers for other BabyYumYum
        Sites are located in the United States of America or in South Africa.
        Your personal information will be hosted in one of these regions,
        depending on the origin of the BabyYumyum Site you are using, and will
        be transferred and processed within that region in accordance with
        applicable data protection laws and regulations.
        <br/>
      </p>
      <p className="subHeadingTermsConditions">
        Transferring Your Personal Information
      </p>
      <p>
        We may transfer your personal information to servers located outside the
        country in which you live or to affiliates or other trusted third
        parties based in other countries so that they may process personal
        information on our behalf. By using a BabyYumYum Site or otherwise
        providing BabyYumYum with personal information, you agree to us doing so
        in accordance with the terms of this Privacy Policy and applicable data
        protection laws and regulations. You should be aware that many countries
        do not afford the same legal protection to personal information as you
        might enjoy in your country of origin. While your personal information
        is in another country, it may be accessed by the courts, law enforcement
        and national security authorities in that country in accordance with its
        laws. Subject to such lawful access requests, we promise that anyone
        processing your personal information outside your country of origin is
        required to implement measures to protect it and is only entitled to
        process it in accordance with BabyYumYum’s instructions.
        <br />
      </p>
      <p className="subHeadingTermsConditions">
        Safeguarding Your Personal Information
      </p>
      <p>
        We take all reasonable precautions to keep your personal information
        secure and require any third parties that handle or process your
        personal information for us to do the same. Access to your personal
        information is restricted to prevent unauthorised access, modification
        or misuse and is only permitted among our employees and agents on a
        need-to-know basis.
        <br />
      </p>
      <p className="subHeadingTermsConditions">
        Your Privacy Rights And Who To Contact
      </p>
      <p>
        If you have any questions, comments or concerns about how we handle your
        personal information, then you may contact us by clicking the Contact Us
        link on the BabyYumyum Site that directed you to this Privacy Policy.
        Alternatively, please go to https://babyyumyum.co.za/ and click on the
        Contact Us link. Your request will be directed to the appropriate
        contact at BabyYumYum or it’s associates.
      </p>
      <p>
        You have the right to tell us if you:
        <div className="bulletPointsTermsConditions">
          • don't want to be contacted in the future by us
          <br />
          • would like a copy of the personal information which we hold about
          you
          <br />
          • would like us to correct, update, or delete your personal
          information in our records
          <br />
          • wish to report any misuse of your personal information
          <br />
        </div>
        To assist us in dealing with your request, please provide your full name
        and details.
        <br />
      </p>
      <p className="subHeadingTermsConditions">Changes To Our Privacy Policy</p>
      <p>
        If you have any questions, comments or concerns about how we handle your
        personal information, then you may contact us by clicking the Contact Us
        link on the BabyYumyum Site that directed you to this Privacy Policy.
        Alternatively, please go to https://babyyumyum.co.za/ and click on the
        Contact Us link. Your request will be directed to the appropriate
        contact at BabyYumYum or it’s associates.
        <br />
      </p>
      <p className="subHeadingTermsConditions">
        Other BabyYumYum Privacy Policies
      </p>
      <p>
        In addition to this Privacy Policy, there may be specific campaigns or
        promotions which will be governed by additional privacy terms or
        policies. We encourage you to read these additional terms or policies
        before participating in any such campaigns or promotions as you will be
        required to comply with them if you participate. Any additional privacy
        terms or policies will be made prominently available to you.
        <br />
      </p>
    </div>
  );
};

export default PrivacyPolicyText;
