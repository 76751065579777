import { createReducer } from '../store/createReducer';
import {
  FUNCTIONMODALS_ALERT_OPEN,
  FUNCTIONMODALS_CONFIRM_OPEN,
  FUNCTIONMODALS_INPUTBOX_OPEN,
  FUNCTIONMODALS_CLOSE
} from './functionModalsConstants';


function getInitialState() {
  return {
    showAlert: false,
    showConfirm: false,
    showInputBox: false,
    text: '',
    initialValue: '',
    okButtonText: 'OK',
    cancelButtonText: 'Cancel'
  };
}

const initialState = getInitialState();

const openAlert = (state, payload) => {
  return {
    ...state,
    showAlert: true,
    text: payload.text,
    okButtonText: payload.okButtonText
  }
};

const openConfirm = (state, payload) => {
  return {
    ...state,
    showConfirm: true,
    text: payload.text,
    okButtonText: payload.okButtonText,
    cancelButtonText: payload.cancelButtonText
  }
};

const openInputBox = (state, payload) => {
  return {
    ...state,
    showInputBox: true,
    text: payload.text,
    initialValue: payload.value,
    okButtonText: payload.okButtonText,
    cancelButtonText: payload.cancelButtonText
  }
};

const closeFunctionModals = (state, payload) => {
  return getInitialState();
};

export default createReducer(initialState, {
  [FUNCTIONMODALS_ALERT_OPEN]: openAlert,
  [FUNCTIONMODALS_CONFIRM_OPEN]: openConfirm,
  [FUNCTIONMODALS_INPUTBOX_OPEN]: openInputBox,
  [FUNCTIONMODALS_CLOSE]: closeFunctionModals
});
