import { combineReducers } from "redux";
import { reducer as FormReducer } from 'redux-form';
import { reducer as ToastrReducer } from 'react-redux-toastr';
import modalReducer from "../modals/modalReducer";
import functionModalsReducer from "../functionModals/functionModalsReducer";
import authReducer from "../../features/auth/authReducer";
import authUserReducer from "../../features/authUser/authUserReducer";
import lookupReducer from "../common/lookups/lookupReducer";
import sideBarReducer from "../../features/nav/Sidebar/data/sideBarReducer";
import { USER_LOGOUT } from "./rootConstants";
import pageContentReducer from "../../features/pageContent/data/pageContentReducer";
import progressiveProfilingQuestionsReducer
  from "../../features/progressiveProfiling/data/progressiveProfilingQuestionsReducer";


const appReducer = combineReducers({
  form: FormReducer,
  modals: modalReducer,
  toastr: ToastrReducer,
  functionModals: functionModalsReducer,
  auth: authReducer,
  authUser: authUserReducer,
  lookups: lookupReducer,
  pageContent: pageContentReducer,
  sideBar: sideBarReducer,
  progressiveProfiling: progressiveProfilingQuestionsReducer
});

const rootReducer = (state, action) => {
  if (action.type === USER_LOGOUT) {
    state = undefined
  }

  return appReducer(state, action);
};

export default rootReducer
