import { toastr } from 'react-redux-toastr';
import {
  AUTH_LOADING,
  AUTH_LOADING_COMPLETED,
  LOGIN,
  LOAD_VERSION
} from './authConstants';
import { httpPost, httpGet, hasValidToken } from "../../app/common/utils/http";
import jwt_decode from 'jwt-decode';
import { USER_LOGOUT } from "../../app/store/rootConstants";
import { convertDate } from "../../../src/app/common/utils/common";


export const loginUser = passwordModel => async (dispatch) => {
  await dispatch({ type: AUTH_LOADING });
  try {
    passwordModel.dob = convertDate(passwordModel.dob);
    const user = await httpPost(`auth/Login`, passwordModel);
    login(user);
    await dispatch({ type: LOGIN });
    return user.ContentPageID;
  } catch (error) {
    toastr.error('Error', error);
    throw error;
  } finally {
    await dispatch({ type: AUTH_LOADING_COMPLETED });
  }
};

const login = user => {
  localStorage.setItem('user', user.Token);
  localStorage.setItem('EDD', user.EstimatedDeliveryDate);
};

export const isLoggedIn = () => {
  if (!hasValidToken()) {
    return false;
  }
  var decodedToken = getDecodedToken();
  return decodedToken.person && decodedToken.person !== '';
};

function getDecodedToken() {
  var token = localStorage.getItem('user');
  var decodedToken = jwt_decode(token);
  return decodedToken;
}

export const logout = () => async (dispatch) => {
  logoutLocalStorage();
  window.setTimeout(() => {
    dispatch({ type: USER_LOGOUT });
  }, 500)
};

export function logoutLocalStorage() {
  
  localStorage.removeItem('user');

  // START QUESTIONS
  localStorage.removeItem('SurveyAnswerDesc');
  localStorage.removeItem('SurveyQuestionID');
  localStorage.removeItem('QuestionDesc');
  localStorage.removeItem('AnswerOptionID');
  localStorage.removeItem('ReactType');
  localStorage.removeItem('QuestionID');
  localStorage.removeItem('AnswerOptions');
  localStorage.removeItem('SurveyAnswerID');
  localStorage.removeItem('ReactComponent');
  localStorage.removeItem('ReactComponent');
  localStorage.removeItem('QuestionTypeCode');
  // END QUESTIONS

  // START USER DETAILS
  localStorage.removeItem("FirstName");
  localStorage.removeItem("LastName");
  localStorage.removeItem("DOB");
  localStorage.removeItem("WeeksPregnant");
  localStorage.removeItem("Address");
  localStorage.removeItem("EDB");
  localStorage.removeItem("EDD");
  localStorage.removeItem("SchemeOption");
  localStorage.removeItem("ContentPageID");
  localStorage.removeItem("BabySize");
  localStorage.removeItem("currentQuestionAmmount");
  localStorage.removeItem("SectionID");
  localStorage.removeItem("questionAmount");
  localStorage.removeItem("BEDD");
  //END USER DETAILS

}

export const unsubscribePushNotifications = async () => {
  try {
    await httpPost('push/Unsubscribe', { userAgent: navigator.userAgent });
  } catch (error) {
    // Don't show an error - just continue
    // toastr.error('Error', "Couldn't unsubscribe from push notifications");
  }
};

export const loadVersion = () => async (dispatch) => {
  try {
    const version = await httpGet(`home/GetReactionVersion`);
    await dispatch({ type: LOAD_VERSION, payload: { version } });
  } catch (error) {
    toastr.error('Error', 'Could not get version');
  }
};


