import React from "react";
import "./FAQs.css";
import { Image } from "semantic-ui-react";
import FullBanner from "../pageContent/FullBanner";
import ScrollTop from "../scrollTop/ScrollTop";

const FAQs = () => {
	return (
		<container className="area">
			<FullBanner />
			<div className="FAQ">
				<ScrollTop />
				<p className="mainHeadingFAQs">
					Frequently Asked Questions
					<br />
				</p>

				<p className="FAQHeading">
					What is NEW under COVID19 lockdown level 1? <br />
				</p>

				<p>
					We know that everything is a bit scary at the moment. We’d like to reassure
					you that together with our partners we have implemented precautionary
					procedures to minimise contact with our mama’s and mama’s-to-be for bag
					deliveries during this time. Please see more information below on our new
					procedures - the MyMomentumBabyYumYum Team.
					<br />
					<br />
				</p>
				<p className="FAQHeading">
					FAQ's
					<br />
				</p>

				<p>
					<b>Is the MyMomentum BabyYumYum portal/programme free?</b>
					<br />
					<br />
					Yes. It is included in your Momentum Maternity Programme and certain
					benefits are applicable to you depending on which plan you are on.
					<br />
					<br />
				</p>
				<p>
					<b>How do I access the portal/programme? </b>
					<br />
					<br />
					Once you have alerted the Momentum call centre that you are expecting a
					baby and have given them consent that you would like access to the portal
					(and the amazing benefits included), we will automatically activate your
					access to the portal and you will be sent a message from the MyMomentum
					BabyYumYum portal.
					<br />
					<br />
				</p>
				<p>
					<b>Can I opt out?</b>
					<br />
					<br />
					Yes, you may opt out at any time, but you will miss out on benefits should
					you wish to opt out of access to the portal.
					<br />
					<br />
				</p>
				<p>
					<b>Can I pick and choose what I want from the portal/programme?</b>
					<br />
					<br />
					No, the programme is all inclusive and the benefits are according to the
					Momentum plan that you are on. <br />
					<br />
				</p>
				<p>
					<b>Will I be charged for data?</b>
					<br />
					<br />
					You will be able to choose how you wish to be communicated with – either
					via SMS, reverse billed USSD or WhatsApp.
					<br />
					<br />
				</p>
				<p>
					<b>How long does the portal/programme run for?</b>
					<br />
					<br />
					Until your baby turns 2 years old. <br />
					<br />
				</p>
				<p>
					<b>
						Once my baby is born, will the MyMomentum BabyYumYum portal/programme
						register the birth for me (with Momentum and at the Department of Health)?
					</b>
					<br />
					<br />
					No, you will need to register the birth of your baby with Home Affairs, but
					the MyMomentum BabyYumYum portal/programme will remind you what to take
					with to hospital (when the birth happens), so that it can be done at the
					hospital for you. You will also need to alert Momentum that the birth has
					taken place.
					<br />
					<br />
				</p>
				<p>
					<b>
						I have informed the MyMomentum BabyYumYum portal/programme that my baby is
						born. Do I still need to tell Momentum?
					</b>
					<br />
					<br />
					Yes, you must communicate with Momentum directly that the birth of your
					baby has occurred and register the birth with Momentum directly. We cannot
					do this for you.
					<br />
					<br />
				</p>
				<p>
					<b>If I have any claims to process who do I send them to?</b>
					<br />
					<br />
					You must send all claims directly to Momentum for processing.
					<br />
					<br />
				</p>
				<p>
					<b>If I have a medical emergency, do I contact MyMomentum BabyYumYum?</b>
					<br />
					<br />
					No. You must contact your nearest emergency assistance provider.
					MyMomentumBabyYumYum can only offer advice or refer your questions to
					experts. <br />
					<br />
				</p>
				<p>
					<b>How do I claim my Swag Bag?</b>
					<br />
					<br />
					We know how excited you are about receiving your Ultra stylish Lily Rose
					Swag Bag{" "}
					<a
						href="https://www.lilyrosecollection.co.za/"
						target="_blank"
						rel="noopener noreferrer"
					>
						{" "}
						(www.lilyrosecollection.co.za)
					</a>
					, At 20 - 24 weeks, please fill out the Bag Delivery Address on the
					microsite
					<a
						href="https://mymomentum.babyyumyum.co.za/"
						target="_blank"
						rel="noopener noreferrer"
					>
						{" "}
						(http://mymomentum.babyyumyum.co.za)
					</a>
					. Please allow 21 - 30 working days for delivery. Prints are seasonal,
					unfortunately print selection cannot be made
					<br />
					<Image
						src="../assets/Momentum Microsite Swag Bags-min.png"
						size="large"
						verticalAlign="middle"
					/>
					<br />
				</p>
				<p>
					<b>Swag Bag Warranty :</b>
					<br />
					<ul>
						<li>
							All bags carry a 30 day warranty from delivery, after which no exchanges
							or returns are permitted.
						</li>
						<li>
							Upon receiving the Swag Bag, members should notify BabyYumYum immediately
							and arrange to return the bag, complete with all contents, within 30 days
							of delivery if there are any faults with it.
						</li>
						<li>
							If a fault occurs after use, within 30 days of receiving the bag, the
							member must return the bag, at their cost, for inspection, upon which the
							supplier will determine if the bag is to be repaired or replaced due to a
							manufacturing default.
						</li>
						<li>
							When exchanging, the member cannot change the print. It remains the same
							as on the original received bag.
						</li>
					</ul>
				</p>
				<p>
					<b>What is SweepSouth?</b>
					<br />
					<br />
					SweepSouth, the online home cleaning platform that connects homeowners to
					domestic workers, knows you’re busy - it is pleased to give you time back
					to spend with the kids, to enjoy your favourite hobby or to simply relax
					while your home cleaning needs get taken care of. Book a SweepSouth clean
					from your desktop, smartphone or tablet - select your home cleaning
					requirements (indoor or outdoor services), confirm the date and time that
					best suits you and you'll be matched with an experienced cleaning
					professional SweepStar in a matter of minutes.
					<br />
					Available 7 days a week from 7am to 5pm in most areas of Cape Town,
					Johannesburg, Pretoria, Centurion and Durban, as well as limited areas in
					Mpumalanga (including Nelspruit & Delmas).
					<br />
					<br />
				</p>
				<p>
					<b>How does the SweepSouth voucher work?</b>
					<br />
					<br />
					1. SIMPLY DOWNLOAD THE APP!
					<br />
					2. TELL US ABOUT YOUR PLACE: We’ll help you to calculate how many hours it
					will take to clean your place.
					<br />
					3. CHOOSE DATE & TIME: Book within 24 hour notice. 7 days a week.
					<br />
					4. MANAGE BOOKINGS: Receive reminders and modify your booking anywhere,
					anytime.
					<br />
					5. SWEEPSTAR ARRIVES: You choose which SweepStar you want when creating
					your booking.
					<br />
					6. REDEEM YOUR VOUCHER: Enter the voucher code into the voucher code field
					to redeem.
					<br />
					7. RATING: Rate your SweepStar and share your feedback with us.
					<br />
					<br />
				</p>
				<p>
					<b>How do I claim for my meal voucher?</b>
					<br />
					<br />
					During your pregnancy, you will be prompted to download the Mr D app{" "}
					<a href=" https://www.mrdfood.com/"> https://www.mrdfood.com/</a>
					<br />
					After giving birth, please visit the microsite{" "}
					<a
						href="https://mymomentum.babyyumyum.co.za/"
						target="_blank"
						rel="noopener noreferrer"
					>
						{" "}
						(http://mymomentum.babyyumyum.co.za)
					</a>{" "}
					and confirm giving birth. Your voucher will then be sent by SMS after 24
					hours, we will then send you your meal voucher code (this is the code you
					use when you checkout on the app) via SMS. Your voucher is worth R350 and
					can only be redeemed with one order and is valid for 6 months from the date
					of issue.
					<br />
					<br />
				</p>
				<p>
					<b>How do I book my nurse?</b>
					<br />
					<br />
					As from 1 April 2021 you will need to contact{" "}
					<b>Momentum Health directly</b> should you wish to book your nurse visits,
					once the baby has been born, by emailing
					<a href="mailto:nursevisit@momentum.co.za">
						{" "}
						nursevisit@momentum.co.za
					</a>{" "}
					or calling
					<a href="tel:0860 117 859"> 0860 117 859</a>
					<br />
					<br />
					Having registered with the maternity program, you have the benefit of
					receiving either home nurse visits or virtual nurse consultations once baby
					is born. Please note that the home visits are dependent on the availability
					of the nurse in your residential area.
					<br />
					<br />
				</p>
			</div>
		</container>
	);
};

export default FAQs;
