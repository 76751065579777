import {
  PERSON_LOADING,
  PERSON_LOADING_COMPLETE,
  LOAD_PERSON,
  PERSON_SUBMITTING,
  PERSON_SUBMITTING_COMPLETED,
  CHILD_SUBMITTING,
  CHILD_SUBMITTING_COMPLETED,
  LOAD_CHILD
} from "./sideBarConstents";
import { httpGet, httpPost } from "../../../../app/common/utils/http";
import { toastr } from "react-redux-toastr";
import { convertDate } from "../../../../app/common/utils/common";

export const loadPerson = () => async dispatch => {
  try {
    await dispatch({
      type: PERSON_LOADING
    });
    const person = await httpGet(`Person/GetPersonDetails`);
    await dispatch({
      type: LOAD_PERSON,
      payload: { ...person }
    });
  } catch (error) {
    //toastr.error('Error', 'Could not load your details');
  } finally {
    await dispatch({ type: PERSON_LOADING_COMPLETE });
  }
};

export const savePerson = person => async (dispatch, getState) => {
  
  await dispatch({ type: PERSON_SUBMITTING });
  try {
    person.EstimatedDeliveryDate = convertDate(person.EstimatedDeliveryDate);
    person.BirthingBuddyDOB = convertDate(person.BirthingBuddyDOB);

    await httpPost("Person/UpdatePersonProfile",person);
    await dispatch({ type: LOAD_PERSON });
    toastr.success("Success", "Your details have been updated");
  } catch (error) {
    toastr.error("Error", error);
    throw error;
  } finally {
    await dispatch({ type: PERSON_SUBMITTING_COMPLETED });
  }
};

export const saveChild = child => async (dispatch, getState) =>{
  console.log(child);
  await dispatch({type: CHILD_SUBMITTING});
  try{
    await httpPost("Person/personChildProfileSubmit", child);
    await dispatch({type: LOAD_CHILD});
  }
  catch(error){
      toastr.error('Error', error)
  }
  finally{
    await dispatch({type: CHILD_SUBMITTING_COMPLETED});
  }
}
