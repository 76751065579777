import React, { Fragment, View } from "react";
import { Image, Grid } from "semantic-ui-react";
import "./TopBanner.css";
import { BrowserView, MobileView } from "react-device-detect";

// const mapState = state => ({
//   loading: state.sideBar.loading,
//
// });

// const actions = {
//   loadPerson
// };

const TopBanner = ({ weeks, event }) => {
  
  return (
    <Fragment>
      <Image
        className={"bannerImage"}
        fluid
        src={"../assets/banner-top-1.jpg"}
      />
      <div
        className="byy-lighterblue"
        style={{
          display: "block",
          height: 100,
          textAlign: "center",
          opacity: 0.8
        }}
      >
        
        <div style={{ display:'flex', flexDirection: "row", flex: 6, justifyContent:"center"}}>
            <div className="blueSircleLeft">
              <p className={"byy-text-darkblue"}>
                <b>{weeks}</b>
              </p>
            </div>
            {event === 'pregnant'&&
            <div>
                <div className="blueSircleCenterPregnant"/>
              </div>
            }
             {event === 'child'&&
            <div>
                <div className="blueSircleCenterChild"/>
              </div>
            }
                <div className="blueSircleRight">
                  <Image src={ "../assets/banner-blue-circle.png" }>
                    <p className={"byy-text-darkblue"}>{ localStorage.getItem("BabySize") }</p>
                  </Image>
                </div>
          </div>
      </div>
    </Fragment>
  );
};

export default TopBanner;
