import React, { Fragment } from 'react';
import { Image } from 'semantic-ui-react';


const BottomBanner = () => {
  return (
    <Fragment>
      <Image fluid src={'../assets/banner-bottom.jpg'}/>
    </Fragment>
  )
};

export default BottomBanner;