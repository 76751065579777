import React, { Fragment } from 'react';
import { Segment, Header, Icon, Button } from 'semantic-ui-react';
import { Helmet } from 'react-helmet';


const NotFound = ({ history }) => {
  return (
    <Fragment>
      <Helmet>
        <title>MMBYY - Page Not Found</title>
      </Helmet>
      <Segment placeholder>
        <Header icon>
          <Icon name='search'/>
          Oops - we cannot find the page you are looking for...
        </Header>
        <Segment.Inline>
          <Button onClick={ () => history.push('/') } primary>
            Return Home
          </Button>
        </Segment.Inline>
      </Segment>
    </Fragment>
  );
};

export default NotFound;
