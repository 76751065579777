import React from 'react';
import { Button } from 'semantic-ui-react';
import ReactHtmlParser from 'react-html-parser';


const FunctionConfirm = ({
                           text,
                           okButtonText,
                           cancelButtonText,
                           confirmOK,
                           closeFunctionModal,
                           closing
                         }) => {
  return (
    <div
      className={ `functionmodal functionmodal-horizontalflip ${
        !closing ? '' : 'functionmodal-horizontalflip-closing'
      }` }
    >
      <div style={ { textAlign: 'justify' } }>{ ReactHtmlParser(text) }</div>
      <Button.Group floated="right" style={ { marginTop: 50 } }>
        <Button
          onClick={ confirmOK }
          positive
          style={ { width: 120 } }
          content={ okButtonText }
          icon="check"
        />
        <Button
          onClick={ closeFunctionModal }
          style={ { width: 120 } }
          content={ cancelButtonText }
          icon="close"
        />
      </Button.Group>
    </div>
  );
};

export default FunctionConfirm;
