import React from "react";
import "./Benefits.css";
import FullBanner from "../pageContent/FullBanner";
import ScrollTop from "../scrollTop/ScrollTop";
import { logout } from "../../features/auth/authActions";

const actions = {
	logout,
};

const Benefits = () => {
	return (
		<container className="area">
			<FullBanner />
			<div className="Ben">
				<p className="mainHeadingBennifits">
					Momentum Medical Scheme Benefits
					<br />
				</p>
				<p className="mainHeading">
					<u>Understanding your maternity benefits:</u>
					<br />
				</p>
				<p className="subHeading">Important Contact Information</p>
				<p>
					Momentum Medical Scheme member call centre:{" "}
					<a href="tel:0860117859">0860 11 78 59</a>
					<br />
					<br />
					Momentum Medical Scheme queries email:{" "}
					<a href="mailto:member@momentumhealth.co.za">
						member@momentumhealth.co.za
					</a>
					<br />
					<br />
					Momentum Medical Scheme claims email:{" "}
					<a href="mailto:claims@momentumhealth.co.za">
						claims@momentumhealth.co.za
					</a>
					<br />
					<br />
					Momentum Medical Scheme website:{" "}
					<a href="www.momentumhealth.co.za">www.momentumhealth.co.za</a>
					<br />
					<br />
				</p>
				<p className="heading">
					Ingwe Option:
					<br />
				</p>
				<p>
					<b>
						Note, to get more information on the following benefits, please contact
						the Momentum Call Centre 0860 11 78 59 and speak to an agent for further
						advice.
					</b>
				</p>
				<p className="subHeading">
					Antenatal visits (Midwives, GP* or gynaecologist)
					<br />
				</p>
				<p>
					On the Ingwe Option, you have cover for up to 4 antenatal visits including
					urine dipstick test (tariff code 4188) at your Ingwe Primary Care network
					GP, gynaecologist or a registered midwife.
					<br />
				</p>
				<p className="subHeading">
					Scans
					<br />
				</p>
				<p>
					On the Ingwe Option you have cover for 2 foetal growth scans.
					<br />
				</p>
				<p className="subHeading">
					Hello Doctor
					<br />
				</p>
				<p>
					Talk to or text a doctor on your phone, anytime, anywhere, 24/7! With Hello
					Doctor, you can talk to, or text a doctor any time of the day or night,
					using your phone, tablet or computer.
					<br />
					<br />
					Once you join the Momentum Medical Scheme maternity program, Hello Doctor
					will email/SMS your username and password to you. Just download the app,
					and log in to access the suite of convenient and easy-to-use health
					services.
					<br />
					<br />
					You can contact Hello Doctor via email at info@hellodoctor.co.za, WhatsApp
					on 073 778 4632 or call them on 087 230 0002 if you need assistance.
					<br />
					<br />
				</p>
				<p className="heading">
					Evolve Option:
					<br />
				</p>
				<p>
					<b>
						Note, to get more information on the following benefits, please contact
						the Momentum Call Centre 0860 11 78 59 and speak to an agent for further
						advice.
					</b>
				</p>
				<p className="subHeading">
					Doula benefit
					<br />
				</p>
				<p>On the Evolve Option you have access to the doula benefit if:</p>
				<p className="bulletPointsBenefits">
					o You will be making use of an Associated gynaecologist, and
					<br />
					o You agree to opt for a natural delivery, and
					<br />o The doula is DOSA or WOMBS accredited.
				</p>

				<p>
					You need to authorise the doula benefit as part of your maternity
					registration.
					<br />
				</p>
				<p className="subHeading">
					Antenatal visits (Midwives, GP* or gynaecologist)
					<br />
				</p>
				<p>
					On the Evolve Option, you have cover for up to 12 antenatal visits
					including urine dipstick test (tariff code 4188) at your Evolve Primary
					Care network GP, gynaecologist or a registered midwife.
					<br />
				</p>
				<p className="subHeading">
					Scans
					<br />
				</p>
				<p>
					On the Evolve Option you have cover for 2 pregnancy scans.
					<br />
				</p>
				<p className="subHeading">
					Paediatrician visits
					<br />
				</p>
				<p>
					On the Evolve Option, you have cover for 2 paediatrician visits in your
					baby's first year.
					<br />
				</p>
				<p className="subHeading">
					Hello Doctor
					<br />
				</p>
				<p>
					Talk to or text a doctor on your phone, anytime, anywhere, 24/7! With Hello
					Doctor, you can talk to or text a doctor any time of the day or night,
					using your phone, tablet or computer.
					<br />
					<br />
					Once you join the Momentum Medical Scheme maternity program, Hello Doctor
					will email/SMS your username and password to you. Just download the app,
					and log in to access the suite of convenient and easy-to-use health
					services.
					<br />
					<br />
					You can contact Hello Doctor via email at info@hellodoctor.co.za, WhatsApp
					on 073 778 4632 or call them on 087 230 0002 if you need assistance.
					<br />
					<br />
				</p>
				<p className="heading">
					Custom Option: <br />
				</p>
				<p>
					<b>
						Note, to get more information on the following benefits, please contact
						the Momentum Call Centre 0860 11 78 59 and speak to an agent for further
						advice.
					</b>
				</p>
				<p className="subHeading">
					Doula benefit
					<br />
				</p>
				<p>On the Custom Option you have access to the doula benefit if:</p>
				<p className="bulletPointsBenefits">
					o You will be making use of an Associated gynaecologist, and
					<br />
					o You agree to opt for a natural delivery, and
					<br />o The doula is DOSA or WOMBS accredited.
				</p>
				<p>
					You need to authorise the doula benefit as part of your maternity
					registration.
					<br />
				</p>
				<p className="subHeading">
					Antenatal visits (Midwives, GP* or gynaecologist)
					<br />
				</p>
				<p>
					On the Custom Option, you have cover for up to 12 antenatal visits
					including urine dipstick test (tariff code 4188) at your Custom Primary
					Care network GP, gynaecologist or a registered midwife.
					<br />
				</p>
				<p className="subHeading">
					Scans
					<br />
				</p>
				<p>
					On the Custom Option you have cover for 2 pregnancy scans.
					<br />
				</p>
				<p className="subHeading">
					Paediatrician visits
					<br />
				</p>
				<p>
					On the Custom Option, you have cover for 2 paediatrician visits in your
					baby's first year.
					<br />
				</p>
				<p className="subHeading">
					Hello Doctor
					<br />
				</p>
				<p>
					Talk to or text a doctor on your phone, anytime, anywhere, 24/7! With Hello
					Doctor, you can talk to, or text a doctor any time of the day or night,
					using your phone, tablet or computer.
					<br />
					<br />
					Once you join the Momentum Medical Scheme maternity program, Hello Doctor
					will email/SMS your username and password to you. Just download the app,
					and log in to access the suite of convenient
					<br />
					and easy-to-use health services.
					<br />
					<br />
				</p>
				<p className="subHeading">
					BellyBabies benefit
					<br />
				</p>
				<p>
					BellyBabies is an exciting offering which provides expecting parents with
					expert antenatal and postnatal support.
					<br />
					<br />
					BellyBabies offers the following:
				</p>
				<p className="bulletPointsBenefits">
					o Access to an online antenatal course with 50 concise educational videos
					that you <br />
					can watch in the comfort of your home.
					<br />o Video consultation with a lactation specialist to help you and your
					baby to establish and maintain a happy breastfeeding routine.
				</p>
				<p>
					You can access these benefits by logging on to: www.bellybabies.co.za
					<br />
					<br />
					Below is the list of tariff codes covered:
				</p>
				<p className="bulletPointsBenefits">
					o Initial video consultation with lactation specialist - Tariff code on
					Incentive Option: 88001
					<br />o Online antenatal and postnatal video classes (one 18 month
					subscription per family) - Tariff code on Incentive Option: 88420
				</p>
				<p className="subHeading">
					Pathology tests
					<br />
				</p>
				<p>
					On the Incentive Option we also cover the following routine pathology tests
					that may be during your pregnancy:
				</p>
				<p className="bulletPointsBenefits">
					o 1 test: Full blood count, blood group, rhesus, platelet count, rubella
					antibody creatinine, glucose strip test, antiglobin test
					<br />
					o 2 tests: Haemaglobin estimation
					<br />
					o 13 tests: Urinalysis
					<br />o On request of your treating doctor (you need to get authorisation
					for these tests): Urine tests (microscopic exams, antibiotic susceptibility
					and culture)
				</p>
				<p className="heading">
					Extender Option:
					<br />
				</p>
				<p>
					<b>
						Note, to get more information on the following benefits, please contact
						the Momentum Call Centre 0860 11 78 59 and speak to an agent for further
						advice.
					</b>
				</p>
				<p className="subHeading">
					Doula benefit
					<br />
				</p>
				<p>On the Extender Option you have access to the doula benefit if:</p>
				<p className="bulletPointsBenefits">
					o You will be making use of an Associated gynaecologist, and
					<br />
					o You agree to opt for a natural delivery, and
					<br />
					o 13 tests: Urinalysis
					<br />o The doula is DOSA or WOMBS accredited.
				</p>
				<p>
					You need to authorise the doula benefit as part of your maternity
					registration.
					<br />
				</p>
				<p className="subHeading">
					Antenatal visits (Midwives, GP* or gynaecologist)
					<br />
				</p>
				<p>
					On the Extender Option, you have cover for up to 12 antenatal visits
					including urine dipstick test (tariff code 4188) at your Extender Primary
					Care network GP, gynaecologist or a registered midwife.
					<br />
				</p>
				<p className="subHeading">
					Scans
					<br />
				</p>
				<p>
					On the Extender Option you have cover for 2 pregnancy scans.
					<br />
				</p>
				<p className="subHeading">
					Paediatrician visits
					<br />
				</p>
				<p>
					On the Extender Option, you have cover for 2 paediatrician visits in your
					baby's first year.
					<br />
				</p>
				<p className="subHeading">
					Hello Doctor
					<br />
				</p>
				<p>
					Talk to or text a doctor on your phone, anytime, anywhere, 24/7! With Hello
					Doctor, you can talk to, or text a doctor any time of the day or night,
					using your phone, tablet or computer.
					<br />
					<br />
					Once you join the Momentum Medical Scheme maternity program, Hello Doctor
					will email/SMS your username and password to you. Just download the app,
					and log in to access the suite of convenient, and easy-to-use health
					services.
					<br />
					<br />
					You can contact Hello Doctor via email at info@hellodoctor.co.za, WhatsApp
					on 073 778 4632 or call them on 087 230 0002 if you need assistance.
					<br />
				</p>
				<p className="subHeading">
					BellyBabies benefit
					<br />
				</p>
				<p>
					BellyBabies is an exciting offering which provides expecting parents with
					expert antenatal and postnatal support.
					<br />
					<br />
					BellyBabies offers the following:
				</p>
				<p className="bulletPointsBenefits">
					o Access to an online antenatal course with 50 concise educational videos
					that you can watch in the comfort of your home.
					<br />o Video consultation with a lactation specialist to help you and your
					baby to establish and maintain a happy breastfeeding routine.
				</p>
				<p>
					You can access these benefits by logging on to: www.bellybabies.co.za
					<br />
					<br />
					Below is the list of tariff codes covered:
				</p>
				<p className="bulletPointsBenefits">
					o Initial video consultation with lactation specialist - Tariff code on
					Incentive Option: 88001
					<br />o Online antenatal and postnatal video classes (one 18 month
					subscription per family) - Tariff code on Incentive Option: 88420
				</p>
				<p className="subHeading">
					Pathology tests
					<br />
				</p>
				<p>
					On the Incentive Option we also cover the following routine pathology tests
					that may be during your pregnancy:
				</p>
				<p className="bulletPointsBenefits">
					o 1 test: Full blood count, blood group, rhesus, platelet count, rubella
					antibody creatinine, glucose strip test, antiglobin test
					<br />
					o 2 tests: Haemaglobin estimation
					<br />
					o 13 tests: Urinalysis
					<br />o On request of your treating doctor (you need to get authorisation
					for these tests): Urine tests (microscopic exams, antibiotic susceptibility
					and culture)
				</p>
				<p className="heading">
					Summit Option: <br />
				</p>
				<p>
					<b>
						Note, to get more information on the following benefits, please contact
						the Momentum Call Centre 0860 11 78 59 and speak to an agent for further
						advice.
					</b>
				</p>
				<p className="subHeading">
					Doula benefit
					<br />
				</p>
				<p>On the Summit Option you have access to the doula benefit if:</p>
				<p className="bulletPointsBenefits">
					o You will be making use of an Associated gynaecologist, and
					<br />
					o You agree to opt for a natural delivery, and
					<br />o The doula is DOSA or WOMBS accredited.
				</p>
				<p>
					You need to authorise the doula benefit as part of your maternity
					registration.
					<br />
					<br />
				</p>
				<p className="subHeading">
					Antenatal visits (Midwives, GP* or gynaecologist)
					<br />
				</p>
				<p>
					On the Summit Option, you have cover for up to 12 antenatal visits
					including urine dipstick test (tariff code 4188) at your Summit Primary
					Care network GP, gynaecologist or a registered midwife.
					<br />
				</p>
				<p className="subHeading">
					Scans
					<br />
				</p>
				<p>
					On the Summit Option you have cover for 2 pregnancy scans.
					<br />
				</p>
				<p className="subHeading">
					Paediatrician visits
					<br />
				</p>
				<p>
					On the Summit Option, you have cover for 2 paediatrician visits in your
					baby's first year.
					<br />
				</p>
				<p className="subHeading">
					Hello Doctor
					<br />
				</p>
				<p>
					Talk to or text a doctor on your phone, anytime, anywhere, 24/7! With Hello
					Doctor, you can talk to, or text a doctor any time of the day or night,
					using your phone, tablet or computer
					<br />
					<br />
					Once you join the Momentum Medical Scheme maternity program, Hello Doctor
					will email/SMS your username and password to you. Just download the app,
					and log in to access the suite of convenient and easy-to-use health
					services.
					<br />
					<br />
					You can contact Hello Doctor via email at info@hellodoctor.co.za, WhatsApp
					on 073 778 4632 or call them on 087 230 0002 if you need assistance.
					<br />
				</p>
				<p className="subHeading">
					BellyBabies benefit
					<br />
				</p>
				<p className="bulletPointsBenefits">
					o Access to an online antenatal course with 50 concise educational videos
					that you can watch in the comfort of your home.
					<br />o Video consultation with a lactation specialist to help you and your
					baby to establish and maintain a happy breastfeeding routine.
				</p>
				<p>
					You can access these benefits by logging on to: www.bellybabies.co.za
					<br />
					<br />
					Below is the list of tariff codes covered:
				</p>
				<p className="bulletPointsBenefits">
					o Initial video consultation with lactation specialist - Tariff code on
					Summit Option: 88001
					<br />
					o Follow-up video consultation with lactation specialist - Tariff code on
					Summit Option: 88006
					<br />
					o Online antenatal and postnatal video classes (one 18 month subscription
					per family) - Tariff code on Summit Option: 88420
					<br />
				</p>
				<p className="subHeading">
					Pathology tests
					<br />
				</p>
				<p>
					On the Summit Option we also cover the following routine pathology tests
					that may be needed during your pregnancy:
				</p>
				<p className="bulletPointsBenefits">
					o 1 test: Full blood count, blood group, rhesus, platelet count, rubella
					antibody, creatinine, glucose strip test, antiglobin test
					<br />
					o 2 tests: Haemaglobin estimation
					<br />
					o 13 tests: Urinalysis
					<br />o On request of your treating doctor (you need to get authorisation
					for these tests): Urine tests (microscopic exams, antibiotic susceptibility
					and culture)
				</p>
				<p className="mainHeading">
					<u>Understanding your MyMomentum BabyYumYum benefits:</u>
					<br />
				</p>
				<p className="heading">
					Important Contact Information:
					<br />
				</p>
				<p>
					BabyYumYum call centre: <a href="tel:0878970359">087 897 0359</a>
					<br />
					<br />
					BabyYumYum email:{" "}
					<a href="mailto:momentum@babyyumyum.co.za">momentum@babyyumyum.co.za</a>
					<br />
					<br />
					MyMomentum BabyYumYum portal:{" "}
					<a href="./" target="_blank" rel="noopener noreferrer">
						mymomentum.babyyumyum.co.za
					</a>
					<br />
					<br />
					USSD number: <a href="tel:*134*667#">*134*667#</a>
					<br />
					<br />
				</p>
				<p className="heading">
					Ingwe Option:
					<br />
				</p>
				<p className="subHeading">
					Information portal
					<br />
				</p>
				<p>
					We have created a special portal that has information relating to how far
					along you are in your pregnancy. You can access this portal here:
					<br />
					<br />
					<a href="./" target="_blank" rel="noopener noreferrer">
						mymomentum.babyyumyum.co.za
					</a>
					<br />
					<br />
				</p>
				<p className="heading">
					Evolve Option:
					<br />
				</p>
				<p className="subHeading">Information portal</p>
				<p>
					We have created a special portal that has information relating to how far
					along you are in your pregnancy. You can access this portal here:
					<br />
					<br />
					<a href="./" target="_blank" rel="noopener noreferrer">
						mymomentum.babyyumyum.co.za{" "}
					</a>
				</p>
				<p className="heading">
					Custom Option: <br />
				</p>
				<p className="subHeading">Information portal</p>
				<p>
					We have created a special portal that has information relating to how far
					along you are in your pregnancy. You can access this portal here:
					<br />
					<br />
					<a href="./" target="_blank" rel="noopener noreferrer">
						mymomentum.babyyumyum.co.za{" "}
					</a>
				</p>
				<p className="heading">
					Incentive Option:
					<br />
				</p>

				<p className="subHeading">
					Mommy Swag Bag
					<br />
				</p>
				<p>
					On the Incentive Option, you will receive a Swag Bag filled with goodies
					especially for you!
					<br />
				</p>
				<p className="subHeading">
					Family Meal Voucher
					<br />
				</p>
				<p>
					We want to offer a helping hand by getting dinner on the table on the
					nights where there isn't enough time to make a meal.
					<br />
					<br />
					On the Incentive Option, you will receive a discount meal voucher to be
					used on the Mr D app.
					<br />
				</p>
				<p className="subHeading">Information portal</p>
				<p>
					We have created a special portal that has information relating to how far
					along you are in your pregnancy. You can access this portal here:
					<br />
					<br />
					<a href="./" target="_blank" rel="noopener noreferrer">
						mymomentum.babyyumyum.co.za{" "}
					</a>
				</p>
				<p className="heading">
					Extender Option:
					<br />
				</p>
				<p className="subJeading">
					Mommy Swag Bag
					<br />
				</p>
				<p>
					On the Extender Option, you will receive a Swag Bag filled with goodies
					especially for you!
					<br />
					<br />
				</p>
				<p className="subHeading">Family Meal Voucher</p>
				<p>
					We want to offer a helping hand by getting dinner on the table on the
					nights where there isn't enough time to make a meal.
					<br />
					<br />
					On the Extender Option, you will receive a discount meal voucher to be used
					on the Mr D app.
					<br />
				</p>
				<p className="subHeading">Information portal</p>
				<p>
					We have created a special portal that has information relating to how far
					along you are in your pregnancy. You can access this portal here:
					<br />
					<br />
					<a href="./" target="_blank" rel="noopener noreferrer">
						mymomentum.babyyumyum.co.za{" "}
					</a>
				</p>
				<p className="heading">
					Summit Option:
					<br />
				</p>
				<p className="subHeading">
					Mommy Swag Bag
					<br />
				</p>
				<p>
					On the Summit Option, you will receive a Swag Bag filled with goodies
					especially for you!
					<br />
				</p>
				<p className="subHeading">
					Family Meal Voucher
					<br />
				</p>
				<p>
					We want to offer a helping hand by getting dinner on the table on the
					nights where there isn't enough time to make a meal.
					<br />
					<br />
					On the Summit Option, you will receive a discount meal voucher to be used
					on the Mr D app.
					<br />
				</p>
				<p className="subHeading">
					Information portal
					<br />
				</p>
				<p>
					We have created a special portal that has information relating to how far
					along you are in your pregnancy. You can access this portal here:
					<br />
					<br />
					<a href="./" target="_blank" rel="noopener noreferrer">
						mymomentum.babyyumyum.co.za
					</a>
					<br />
				</p>
				<p className="mainHeadingBennifits">Momentum Health4Me Benefits</p>
				<p className="mainHeading">
					<u>Understanding your maternity benefits:</u>
				</p>
				<p className="subHeading">Important Contact Information</p>
				<p>
					Health4Me call centre: <a href="tel:0860102903">0860 10 29 03</a> <br />
					Health4Me email:{" "}
					<a href="mailto:Health4Mematernity@momentum.co.za">
						Health4Mematernity@momentum.co.za
					</a>
					<br />
				</p>
				<p className="heading">
					Health4Me
					<br />
				</p>
				<p>
					Antenatal GP visits at a Momentum CareCross Network GP
					<br />
					<br />
					2 2D foetal growth scans per member per pregnancy
					<br />
					<br />
					Antenatal pathology tests when members visit their Momentum CareCross
					Network GP, and are referred by their Network GP, according to the
					Health4Me antenatal pathology list
					<br />
					<br />
					Antenatal vitamins provided in accordance with the Momentum CareCross
					Network prescribed acute medication formulary
					<br />
				</p>
				<p className="subHeading">Hello Doctor</p>
				<p>
					Talk to or text a doctor on your phone, anytime, anywhere, 24/7! With Hello
					Doctor, you can talk to, or text a doctor any time of the day or night,
					using your phone, tablet or computer. <br />
					<br />
					Once you join the Health4Me maternity programme, Hello Doctor will
					email/SMS your username and password to you. Just download the app, and log
					in to access the suite of the app, and log in to access the suite of
					<br />
					<br />
					You can contact Hello Doctor via email at info@hellodoctor.co.za, WhatsApp
					on 073 778 4632 or call them on 087 230 0002 if you need assistance.
				</p>
				<p className="mainHeading">
					<u>Understanding your MyMomentum BabyYumYum benefits:</u>
				</p>
				<p className="subHeading">Important Contact Information</p>
				<p>
					BabyYumYum call centre: <a href="tel:0878970359">087 897 0359</a>
					<br />
					<br />
					BabyYumYum email:{" "}
					<a href="mailto:momentum@babyyumyum.co.za">momentum@babyyumyum.co.za</a>
					<br />
					<br />
					MyMomentum BabyYumYum portal:{" "}
					<a href="./" target="_blank" rel="noopener noreferrer">
						mymomentum.babyyumyum.co.za
					</a>
					<br />
					<br />
				</p>
				<p className="heading">Health4Me</p>
				<p className="subHeading">Information portal</p>
				<p>
					We have created a special portal that has information relating to how far
					along you are in your pregnancy. You can access this portal here:
				</p>
				<p style={{ color: "#e81f78" }}>
					<a
						style={{ color: "#e81f78" }}
						href="./"
						target="_blank"
						rel="noopener noreferrer"
					>
						mymomentum.babyyumyum.co.za
					</a>
				</p>
			</div>
			<ScrollTop />
		</container>
	);
};

export default Benefits;
