import React from 'react';
import { connect } from 'react-redux';
import cuid from 'cuid';


const modalLookup = {};

const mapState = state => ({
  currentModals: state.modals
});

const ModalManager = ({ currentModals }) => {
  let renderedModals = [];
  if (currentModals && currentModals.modals.length > 0) {
    currentModals.modals.forEach(model => {
      const ModalComponent = modalLookup[model.modalType];
      renderedModals.push(
        <ModalComponent
          { ...model.modalProps }
          animateFadeInTime={ currentModals.animateFadeInTime }
          animateFadeOutTime={ currentModals.animateFadeOutTime }
        />
      );
    });
  }
  return renderedModals.map(renderedModal => (
    <span key={ cuid() }>{ renderedModal }</span>
  ));
};

export default connect(mapState)(ModalManager);
