import {
  FUNCTIONMODALS_ALERT_OPEN,
  FUNCTIONMODALS_INPUTBOX_OPEN,
  FUNCTIONMODALS_CLOSE,
  FUNCTIONMODALS_CONFIRM_OPEN
} from "./functionModalsConstants";


let _functionModalOptions = null;
export const alert = (text, okButtonText = 'OK') => async (
  dispatch,
  getState
) => {
  await dispatch({
    type: FUNCTIONMODALS_ALERT_OPEN,
    payload: {
      text,
      okButtonText
    }
  });
};

export const confirm = (text, functionModalOptions, okButtonText = 'OK', cancelButtonText = 'Cancel') => async (
  dispatch,
  getState
) => {
  await dispatch({
    type: FUNCTIONMODALS_CONFIRM_OPEN,
    payload: {
      text,
      okButtonText,
      cancelButtonText
    }
  });
  _functionModalOptions = functionModalOptions;
};

export const inputBox = (text, functionModalOptions, initialValue, okButtonText = 'OK', cancelButtonText = 'Cancel') => async (
  dispatch,
  getState
) => {
  await dispatch({
    type: FUNCTIONMODALS_INPUTBOX_OPEN,
    payload: {
      text,
      initialValue,
      okButtonText,
      cancelButtonText
    }
  });
  _functionModalOptions = functionModalOptions;
};

export const confirmOK = () => async (
  dispatch,
  getState
) => {
  _functionModalOptions.onOk();
};

export const inputBoxResult = (result) => async (
  dispatch,
  getState
) => {
  _functionModalOptions.onOk(result);
};

export const closeFunctionModal = () => async (
  dispatch,
  getState
) => {
  await dispatch({
    type: FUNCTIONMODALS_CLOSE
  });
  _functionModalOptions = null;
};