import { toastr } from 'react-redux-toastr';
import {
  NEWEDD_LOADING,
  NEWEDD_LOADING_COMPLETED,
  NEWEDD
} from './submitNewDateConstants';
import { httpPost } from "../../../app/common/utils/http";
import { convertDate } from "../../../app/common/utils/common";


export const submitDates = dateModel => async dispatch => {
  await dispatch({ type: NEWEDD_LOADING });
  try {
    dateModel.EstimatedDeliveryDate = convertDate(dateModel.EstimatedDeliveryDate);
    dateModel.EstimatedBirthDate = convertDate(dateModel.EstimatedBirthDate);

    const answer = await httpPost("Person/UpdatePersonEstimateDates", dateModel);

    await dispatch({ type: NEWEDD });
    return answer.EstimatedDeliveryDate;
  } catch (error) {
    // console.log(dateModel); 
    toastr.error("Error", error);

    throw error;
  } finally {
    await dispatch({ type: NEWEDD_LOADING_COMPLETED });
  }
};

export const notPregnant = value => async dispatch =>{
  console.log(value);
  await dispatch ({type: NEWEDD_LOADING});
  try{
    await httpPost("Person/UpdatePersonStatus",value);
    await dispatch({ type: NEWEDD });
  }catch(error){
    toastr.error("Error", error);
    throw error;
  }finally{
    await dispatch({ type: NEWEDD_LOADING_COMPLETED });
  }
}

