import React from 'react';
import "./TermsConditionsPage.css";
import FullBanner from "../pageContent/FullBanner";
import ScrollTop from "../scrollTop/ScrollTop";
import { Accordion, Icon } from "semantic-ui-react";

const TermsConditionsText = () => {
  return(
      <div>
        <p className="subHeadingTermsConditions">
           1. How do I access the MyMomentum BabyYumYum Services?
         </p>
         <p>
           Access by Users to the MyMomentum BabyYumYum Services will be for
           Momentum Health, Medical Scheme and Health4Me members who are on the
           maternity programmes.
           <br />
           <br />
           The User will be required to accept these terms and conditions, which
           includes the Terms and Conditions of Use, before accessing the
           services.
           <br />
           <br />
           All access is subject to the approval of BabyYumYum and Momentum in
           its discretion. BabyYumYum will be entitled to take teps to restrict
           or suspend the services to a User who misuses the MyMomentum
           BabyYumYum services.
           <br />
         </p>
         <p className="subHeadingTermsConditions">
           2. What is the MyMomentum BabyYumYum Portal?
         </p>
         <p>
           The MyMomentumBabyYumYum Portal is an electronic platform designed to
           promote a better understanding, knowledge and awareness of pregnancy
           and baby development acting as an interface between yourself (the
           “User”) and various related experts in South Africa; presenting you
           with a greater choice of and access to various pregnancy care topics,
           as well as the opportunity to communicate with registered medical
           practitioners to discuss your pregnancy and baby related questions.
           <br />
           <br />
           Contact number for MyMomentum BabyYumYum:{" "}
           <a href="tel:0878970359">087 897 0359</a>
           <br />
           <br />
           Contact email for MyMomentum BabyYumYum:{" "}
           <a href="mailto:momentum@babyyumyum.co.za">
             momentum@babyyumyum.co.za
           </a>
         </p>
         <p>The MyMomentum BabyYumYum Portal (“MMBYY”) includes:</p>
         <p className="bulletPointsTermsConditions">
           •Access to the mymomentum.babyyumyum.co.za portal, which provides
           information related to your pregnancy and the birth of your baby,
           <br />
           •Nurse visit, which entitles you (scheme option selection and benefits
           covered dependent) a nurse visit the day after you return from
           hospital, a nurse visit 2 weeks after your initial nurse visit and 6
           weeks after your initial nurse visit
           <p className="markedPointsTerms">
             i. If you fail to request an initial nurse visit 1 (one) week after
             the birth, the benefit will be forfeited unless complications and
             extended time in hospital can be proven as per your hospital visit.
             If this is the case, the benefit will be re- instated at the
             discretion of BabyYumYum.
             <br />
             ii. If you fail to request the Week 2 nurse visit 1 (one) week after
             the initial nurse visit, the benefit will be forfeited unless
             complications and extended time in hospital can be proven as per
             your hospital visit. If this is the case, the benefit will be
             reinstated at the discretion of BabyYumYum.
             <br />
             iii. If you fail to request the Week 6 nurse visit 1 (one) week
             after the Week 2 nurse visit, the benefit will be forfeited by the
             User unless complications and extended time in hospital can be
             proven. If this is the case, the benefit will be reinstated at the
             discretion of BabyYumYum.
           </p>
           •A Mommy Swag Bag, which is filled with goodies for mom (maternity
           plan dependent),
           <p className="markedPointsTerms">
             i. BabyYumYum will provide a message to the user about when it is
             time to deliver their bag.
             <br />
             ii. If the user fails to notify BabyYumYum that they are ready to
             receive their Mommy Swag Bag after Week 22, this benefit shall be
             forfeited by the User and they shall not receive a Mommy Swag Bag.
             <br />
           </p>
           •Family meal vouchers, which you can use in conjunction with the
           selected food delivery app.
           <p className="markedPointsTerms">
             i. Meal voucher must be claimed within 2 (two) weeks after birth.
             Hereafter, voucher may not be claimed and this benefit will be
             forfeited.
             <br />
             ii. A voucher is limited to a single use on the selected food
             delivery app. The voucher can be used once up to the maximum rand
             denomination and no remaining balances per voucher will be carried
             over to future orders, eg.: The voucher is valid for R350. If an
             order is placed for R300, the remaining R50 shall expire and will
             not be carried over to a following order. iii. Vouchers are not
             exchangeable for cash. iv. The voucher expires 6 months from date of
             issue. v. BabyYumYum and its affiliates shall have no obligation to
             ensure that the person attempting to redeem the voucher on the food
             delivery app is in fact the lawful recipient of such voucher (coupon
             code). Should a User lose their voucher number prior to it being
             redeemed, they must contact the BabyYumYum call centre who will
             reissue the same voucher to the User.
           </p>
         </p>
         <p>
           The above benefits are subject to which Option/Product the User is on.
           Below are the benefits per Option/Product:
         </p>
         <p className="headingOptionTermsCondition">Ingwe Option:</p>
         <p className="subHeadingTermsConditions">Nurse home visit</p>
         <p>
           We know how stressful it can be to leave the house with a newborn, so
           for your convenience, we will organise for a nurse to come to you.
           <br />
           <br />
           On the Ingwe Option you have access to a nurse home visit the day
           after your return from hospital and a nurse visit 2 weeks after your
           initial nurse visit.
         </p>
         <p className="subHeadingTermsConditions">Information portal</p>
         <p>
           We have created a special portal that has information relating to how
           far along you are in your pregnancy. You can access this portal here:
           <br />
           <br />
           <a
             href="./"
             target="_blank"
             rel="noopener noreferrer"
             style={{ color: "#e81f78" }}
           >
             mymomentum.babyyumyum.co.za{" "}
           </a>
         </p>
         <p className="headingOptionTermsCondition">Evolve Option:</p>
         <p className="subHeadingTermsConditions">Nurse home visit</p>
         <p>
           We know how stressful it can be to leave the house with a newborn, so
           for your convenience, we will organise for a nurse to come to you.
           <br />
           <br />
           On the Evolve Option you have access to a nurse home visit the day
           after your return from hospital and a nurse visit 2 weeks after
           initial nurse visit.
         </p>
         <p className="subHeadingTermsConditions">Information portal</p>
         <p>
           We have created a special portal that has information relating to how
           far along you are in your pregnancy. You can access this portal here:
           <br />
           <br />
           <a
             href="./"
             target="_blank"
             rel="noopener noreferrer"
             style={{ color: "#e81f78" }}
           >
             mymomentum.babyyumyum.co.za{" "}
           </a>
         </p>
         <p className="headingOptionTermsCondition">Custom Option:</p>
         <p className="subHeadingTermsConditions">Nurse home visit</p>
         <p>
           We know how stressful it can be to leave the house with a newborn, so
           for your convenience, we will organise for a nurse to come to you.
           <br />
           <br />
           On the Custom Option you have access to a nurse home visit the day
           after your return from hospital and a nurse visit 2 weeks after your
           initial nurse visit.
         </p>
         <p className="subHeadingTermsConditions">Information portal</p>
         <p>
           We have created a special portal that has information relating to how
           far along you are in your pregnancy. You can access this portal here:
           <br />
           <br />
           <a
             href="./"
             target="_blank"
             rel="noopener noreferrer"
             style={{ color: "#e81f78" }}
           >
             mymomentum.babyyumyum.co.za{" "}
           </a>
         </p>
         <p className="headingOptionTermsCondition">Incentive Option:</p>
         <p className="subHeadingTermsConditions">Nurse home visit</p>
         <p>
           We know how stressful it can be to leave the house with a newborn, so
           for your convenience, we will organise for a nurse to come to you.{" "}
           <br />
           <br />
           On the Incentive Option you have access to a nurse home visit:
         </p>
         <p className="bulletPointsTermsConditions">
           • the day after your return from hospital
           <br />
           • 2 weeks after your initial nurse visit
           <br />• 6 weeks after your initial nurse visit
         </p>
         <p className="subHeadingTermsConditions">Mommy Swag Bag</p>
         <p>
           On the Incentive Option, you will receive a Swag Bag filled with
           goodies especially for you!
         </p>
         <p className="subHeadingTermsConditions">Family Meal Voucher</p>
         <p>
           We want to offer a helping hand by getting dinner on the table on the
           nights where there isn't enough time to make a meal.
           <br />
           <br />
           On the Incentive Option, you will receive a discount meal voucher to
           be used on the Mr D app.
         </p>
         <p className="subHeadingTermsConditions">Information portal</p>
         <p>
           We have created a special portal that has information relating to how
           far along you are in your pregnancy. You can access this portal here:
           <br />
           <br />
           <a
             href="./"
             target="_blank"
             rel="noopener noreferrer"
             style={{ color: "#e81f78" }}
           >
             mymomentum.babyyumyum.co.za{" "}
           </a>
         </p>
         <p className="headingOptionTermsCondition">Extender Option:</p>
         <p className="subHeadingTermsConditions">Nurse home visit</p>
         <p>
           We know how stressful it can be to leave the house with a newborn, so
           for your convenience, we will organise for a nurse to come to you.
           <br />
           <br />
           On the Extender Option you have access to a nurse home visit:
         </p>
         <p className="bulletPointsTermsConditions">
           • the day after your return from hospital
           <br />
           • 2 weeks after your initial nurse visit
           <br />• 6 weeks after your initial nurse visit
         </p>
         <p className="subHeadingTermsConditions">Mommy Swag Bag</p>
         <p>
           On the Extender Option, you will receive a Swag Bag filled with
           goodies especially for you!
         </p>
         <p className="subHeadingTermsConditions">Family Meal Voucher</p>
         <p>
           We want to offer a helping hand by getting dinner on the table on the
           nights where there isn't enough time to make a meal.
           <br />
           <br />
           On the Extender Option, you will receive a discount meal voucher to be
           used on the Mr D app.
         </p>
         <p className="subHeadingTermsConditions">Information portal</p>
         <p>
           We have created a special portal that has information relating to how
           far along you are in your pregnancy. You can access this portal here:
           <br />
           <br />
           <a
             href="./"
             target="_blank"
             rel="noopener noreferrer"
             style={{ color: "#e81f78" }}
           >
             mymomentum.babyyumyum.co.za{" "}
           </a>
         </p>
         <p className="headingOptionTermsCondition">Summit Option:</p>
         <p className="subHeadingTermsConditions">Nurse home visit</p>
         <p>
           We know how stressful it can be to leave the house with a newborn, so
           for your convenience, we will organise for a nurse to come to you.{" "}
           <br />
           <br />
           On the Summit Option you have access to a nurse home visit:
         </p>
         <p className="bulletPointsTermsConditions">
           • the day after your return from hospital
           <br />
           • 2 weeks after your initial nurse visit
           <br />• 6 weeks after your initial nurse visit
         </p>
         <p className="subHeadingTermsConditions">Mommy Swag Bag</p>
         <p>
           On the Summit Option, you will receive a Swag Bag filled with goodies
           especially for you!
         </p>
         <p className="subHeadingTermsConditions">Family Meal Voucher</p>
         <p>
           We want to offer a helping hand by getting dinner on the table on the
           nights where there isn't enough time to make a meal. <br />
           <br />
           On the Summit Option, you will receive a discount meal voucher to be
           used on the Mr D app.
         </p>
         <p className="subHeadingTermsConditions">Information portal</p>
         <p>
           We have created a special portal that has information relating to how
           far along you are in your pregnancy. You can access this portal here:
           <br />
           <br />
           <a
             href="./"
             target="_blank"
             rel="noopener noreferrer"
             style={{ color: "#e81f78" }}
           >
             mymomentum.babyyumyum.co.za{" "}
           </a>
         </p>
         <p className="headingOptionTermsCondition">Health4Me Product</p>
         <p className="subHeadingTermsConditions">Nurse home visit</p>
         <p>
           We know how stressful it can be to leave the house with a newborn, so
           for your convenience, we will organise for a nurse to come to you.{" "}
           <br />
           <br />
           On the Ingwe Option you have access to a nurse home visit the day
           after your return from hospital and a nurse visit 2 weeks after your
           initial nurse visit.
         </p>
         <p className="subHeadingTermsConditions">Information portal</p>
         <p>
           We have created a special portal that has information relating to how
           far along you are in your pregnancy. You can access this portal here:
           <br />
           <br />
           <a
             href="./"
             target="_blank"
             rel="noopener noreferrer"
             style={{ color: "#e81f78" }}
           >
             mymomentum.babyyumyum.co.za{" "}
           </a>
         </p>
         <p>
           These services may be reviewed, updated, added to and/or removed by
           BabyYumYum and Momentum from time to time, meaning that the above list
           of services is not exhaustive and that this definition will be varied
           from time to time to bring it in line with the current services
           offered by BabyYumYum and Momentum.
           <br />
           <br />
           The MyMomentum BabyYumYum Services are not intended nor suited to be a
           replacement or substitute for professional medical advice,
           consultation, examination, diagnosis or treatment relative to a
           specific medical question or condition. All information obtained by a
           User on, through and/or by means of the MyMomentum BabyYumYum
           Services, is for informational and educational purposes only.
         </p>
         <p className="subHeadingTermsConditions">
           3. The User represents and warrants to and in favour of MyMomentum
           BabyYumYum that he or she:
         </p>
         <p className="bulletPointsTermsConditions">
           • has carefully read the terms and conditions and each of the
           additional terms and conditions that may apply to the applicable
           selected MyMomentum BabyYumYum Service; and
           <br />
           • is over the age of 18 (eighteen);
           <br />
           • understands the financial commitments in subscribing for the
           service; and
           <br />
           • is and will at all material times be in a financial position to meet
           his or her obligations under this Agreement; and
           <br />• is authorised to use such mobile or other electronic device or
           payment card to make payment, as the case may be, and that there are
           sufficient funds available to pay for the order.
         </p>
         <p>
           The User hereby specifically represents and warrants to and in favour
           of MyMomentum BabyYumYum that all information offered, proffered,
           provided, discussed with and/or communicated to any health
           practitioner whilst making use of a selected service will be
           absolutely true and correct in every respect and regard – hereby
           acknowledging the importance as to the serious consequences if such
           information is not as represented and warranted.
         </p>
         <p>
           Subject to any and/or all applicable laws, MyMomentum BabyYumYum makes
           no warranty or representation, whether expressly or implicitly:
         </p>
         <p className="bulletPointsTermsConditions">
           • about any component of a selected MyMomentum BabyYumYum Service, or
           that the User will be able to have access to or continue to have
           access to the service or that such access will be continuous and/or
           uninterrupted; and/or
           <br />
           • as to the quality of reception by the User of the service, or any
           aspect thereof, as MyMomentum BabyYumYum cannot be responsible or
           liable for electronic media or conveyance through, on and/or via such
           electronic means as these are and will be beyond its control; and/or
           <br />
           • that the software used to provide the selected service including any
           software applications, is virus or error-free, flawless without fault;
           and/or
           <br />
           • that the services will at all times be free of errors or
           interruptions, be always available, fit for any purpose, not infringe
           any third party rights, be secure and reliable, or will conform to its
           delivery timeline requirements; and
           <br />• MyMomentum BabyYumYum will use its reasonable endeavours to
           make its services and products available to the User and to maintain
           the availability thereof on the understanding that such services are
           provided on an “as is” and “as available” basis.
         </p>
         <p>
           MyMomentum BabyYumYum, in the absence of its gross negligence or
           wilful misconduct will not be liable to any person for the content of
           and/or the use of materials constituting the selected service whether
           provided by MyMomentum BabyYumYum or a third party.
         </p>
         <p className="subHeadingTermsConditions">
           4. Payment and payment methods
         </p>
         <p>
           The MyMomentum BabyYumYum Services are only available to Momentum
           Maternity Benefits members who have a valid Momentum Maternity
           Benefits members who have a valid
         </p>
         <p className="subHeadingTermsConditions">5. Personal Information</p>
         <p>
           The User expressly gives and, to the extent applicable, is and will be
           deemed to have provided express permission for the collection,
           collation, processing, receipt, storage, recording, updating,
           modifying, retrieval, dissemination and distribution of his or her
           personal information for the purposes of the MyMomentumBabyYumYum
           Service as set out herein.
         </p>
         <p>
           Subject to all and/or any laws to the contrary, the User must and
           will:
         </p>
         <p className="bulletPointsTermsConditions">
           • provide MyMomentum BabyYumYum with the personal and contact details
           and information as required by it in order to administer and/or
           deliver the selected service, as the case may be; and/or
           <br />• inform MyMomentum BabyYumYum and/or the Third Party Benefit
           Programme (if applicable), in writing, within 2 (two) days of any
           change to any of the information provided by the User in relation to
           the selected MyMomentum BabyYumYum Service.
         </p>
         <p>
           MyBabyYumYum will be processing personal information relating to you
           and your dependents, and MyBabyYumYum acknowledges that it may process
           such personal information only for the purposes of rendering the
           services in terms of this Agreement.
         </p>
         <p>
           MyBabyYumYum will treat your personal information that comes to its
           knowledge as confidential and will not disclose this personal
           information to any other party unless required by law or in the course
           of the proper performance of the services, and then only with the
           written consent of Momentum Health Solutions (MHS)
         </p>
         <p className="bulletPointsTermsConditions">
           • MyBabyYumYum will limit access to personal information to only to
           those of its employees or colleagues who need to know it in order to
           enable MyBabyYumYum to perform the services.
         </p>
         <p className="subHeadingTermsConditions">6. Limitation of liability</p>
         <p>
           The User hereby indemnifies and holds MyMomentum BabyYumYum and any
           and all of its affiliates harmless against any claim brought by any
           third party or person against MyMomentum BabyYumYum and any of its
           affiliates:
         </p>
         <p className="bulletPointsTermsConditions">
           • arising out of a breach by the User of the provisions of this
           Agreement; and/or
           <br />
           • as a result of access to or use by a third party or person of the
           selected service; and/or
           <br />
           • associated with the provision of the selected service to the User.
           <br />
           • MyMomentum BabyYumYum and the attending doctor or expert shall not
           be liable for any direct damages. In addition, to the maximum extent
           permitted by law, MyMomentum BabyYumYum and the attending doctor or
           expert shall not be liable for any special, punitive, indirect,
           incidental or consequential damages, including but not limited to
           personal injury, wrongful death or loss of use, whether in any action
           in warranty, contract, delict (including, but not limited to
           negligence or fundamental breach), or otherwise arising out of or in
           any way connected with the use of, or the inability to use, these
           services or any material or information contained in, accessed through
           these services.
           <br />
           • Indemnity
           <br />
           • You indemnify, defend and hold harmless MyMomentum BabyYumYum and
           the attending doctor or expert and their affiliates from and against
           all losses, liability, expenses, damages and costs, including all
           attorney’s fees, arising out of or related to any breach of the terms
           of use, your relationship with MyMomentum BabyYumYum and the attending
           doctor or expert any negligent or wrongful action or omission by you
           related to your use of services through MyMomentum BabyYumYum, or any
           negligent or wrongful use of the services, including negligent or
           wrongful conduct by you or any other person acting on your behalf.
           <br />
           • Waiver
           <br />
           • MyMomentum BabyYumYum shall not be responsible or liable, directly
           or indirectly, for any damage or loss caused,or alleged to be caused,
           by or in connection with, use of or reliance on, any information,
           services available on or through MyMomentum BabyYumYum and/or all
           those associated and/or affiliated with it, including the attending
           doctors. <br />• Accordingly, in consideration for the services so
           provided, you, the User requesting the call back, hereby waive your or
           your family member’s (including but not limited to that of your and
           your minor child’s estate and/or any beneficiary thereunder) right to
           and actually bring any normal, reasonable and/or legally enforceable
           claim, action, proceeding, application and/or demand against
           MyMomentum BabyYumYum and/or all those associated and/or affiliated
           with it, including the attending doctors, for any and/or all claims,
           costs, damages and/or expenses or otherwise arising out of and/or
           suffered by you and/or your family member as a result or consequence
           of making use of these services or otherwise.
         </p>
         <p className="subHeadingTermsConditions">7. Rights – YOUR CHOICE</p>
         <p>
           It is your right to ask the doctor any questions you have about your
           health to help you to make an informed decision about what to do about
           it. Please note that by using the service and by interacting with a
           doctor or expert, you are giving your consent to participate with a
           doctor or expert, you are giving your consent to participate or expert
           cannot give you a medical diagnosis or offer you medical treatment
           over email, but will give you information about what you can do for
           the health problems that triggered your call back request. All emails
           will be recorded and kept confidential. All text- based messages will
           be stored and kept confidential. However, if you participate in open
           group text-based forums or discussions, the information you give will
           be publicly available and for all those participating to see; since
           there is no confidentiality in open forums.
         </p>
         <p className="subHeadingTermsConditions">
           8. Not a substitute for in person medical care
         </p>
         <p>
           The service you receive is not clinical medical advice, but rather
           preventative healthcare information of an educational nature to
           empower you to make an informed decision and choice; it does not
           replace and is not intended to replace in person medical care, advice,
           instruction or treatment. As such, you are urged to immediately seek
           medical treatment if and to the extent the reason for your call
           persists.
         </p>
         <p className="subHeadingTermsConditions">9. No warranty</p>
         <p>MyMomentum BabyYumYum and the attending doctor or expert:</p>
         <p className="bulletPointsTermsConditions">
           • make no warranty as to the content of any information and/or
           response; and
           <br />
           • do not guarantee that a conversation with a doctor or expert via the
           telephone or email and/or text message is the appropriate course of
           action for your particular healthcare problem.
           <br />
           • No change in health condition
           <br />• You are solely responsible for all information and/or
           communication given during the telephone/email conversation text
           message or other communication. You undertake to contact your doctor
           immediately should your condition change or your symptoms worsen. If
           you require urgent care, you should contact your local emergency
           services immediately. Any and all services that you acquire through
           MyMomentum BabyYumYum and the attending doctor shall be solely for
           your personal use and/or that of your family member requiring the
           services.
         </p>
         <p className="subHeadingTermsConditions">10. Risks</p>
         <p>
           You understand and accept that there is an inherent risk in not having
           a physical examination. Your use of the services is at your sole risk
           and you assume full responsibility for all risks associated therewith.
           All information or services provided through the MyMomentum BabyYumYum
           Services are provided without any warranty of any kind, express or
           implied. To the fullest extent permissible under South African law or
           any other law, MyMomentum BabyYumYum and the attending doctor or
           expert, their shareholders, affiliates, directors, officers, managers,
           employees, advisers and/or other representatives hereby disclaim all
           representations and warranties, express or implied, statutory or
           otherwise, including but not limited to warranties of fitness for a
           particular purpose. Without limiting the aforegoing, there is and
           shall be no warranty as to the reliability, accuracy, timeliness,
           usefulness, adequacy, completeness or suitability of the services
           and/or the products so provided.
         </p>
         <p>Acceptance of MyMomentum BabyYumYum’s Terms & Conditions</p>
         <p>
           By subscribing for the MyMomentum BabyYumYum Services you will be
           deemed to have read, understood and agreed to both these Terms and
           Conditions of Use and the Standard Subscription Terms and Conditions.
         </p>
      </div>
  );
}

export default TermsConditionsText;