import React from 'react';
import { TextArea, Button } from 'semantic-ui-react';
import ReactHtmlParser from 'react-html-parser';


const FunctionInputBox = ({
                            text,
                            okButtonText,
                            cancelButtonText,
                            inputBoxResult,
                            closeFunctionModal,
                            inputEl,
                            changeResult,
                            initialValue,
                            result,
                            closing
                          }) => {
  return (
    <div
      className={ `functionmodal functionmodal-horizontalflip ${
        !closing ? '' : 'functionmodal-horizontalflip-closing'
      }` }
    >
      <div style={ { textAlign: 'justify' } }>{ ReactHtmlParser(text) }</div>
      <TextArea
        ref={ inputEl }
        onChange={ e => changeResult(e.target.value) }
        style={ { width: '100%', marginTop: 10 } }
        rows={ 8 }
        value={ initialValue }
      />
      <Button.Group floated="right" style={ { marginTop: 10 } }>
        <Button
          onClick={ inputBoxResult }
          positive
          style={ { width: 120 } }
          content={ okButtonText }
          icon="check"
          disabled={ result === '' }
        />
        <Button
          onClick={ closeFunctionModal }
          style={ { width: 120 } }
          content={ cancelButtonText }
          icon="close"
        />
      </Button.Group>
    </div>
  );
};

export default FunctionInputBox;
