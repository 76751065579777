import {
  PAGECONTENT_LOADING,
  PAGECONTENT_LOADING_COMPLETED,
  LOAD_PAGECONTENT,
  PAGECONTENT_DETAIL_LOADING,
  LOAD_PAGECONTENT_DETAIL,
  PAGECONTENT_DETAIL_LOADING_COMPLETED
} from './pageContentConstants';
import { createReducer } from '../../../app/store/createReducer';


const initialState = {
  loading: false,
  loadingDetail: false,
  content: [],
  contentDetail: []
};

const loading = (state, payload) => {
  return {
    ...state,
    loading: true
  };
};

const loadingCompleted = (state, payload) => {
  return {
    ...state,
    loading: false
  };
};

const loadContent = (state, payload) => {
  const content = [
    ...state.content,
    { contentPageID: payload.contentPageID, ...payload.content }
  ];

  return {
    ...state,
    content
  };
};

const loadingDetail = (state) => {
  return {
    ...state,
    loadingDetail: true
  };
};

const loadingDetailCompleted = (state) => {
  return {
    ...state,
    loading: false
  };
};

const loadContentDetail = (state, payload) => {
  const contentDetail = [
    ...state.contentDetail,
    { contentPageID: payload.contentPageID, ...payload.contentDetail }
  ];
  return {
    ...state,
    contentDetail
  };
};

export default createReducer(initialState, {
  [PAGECONTENT_LOADING]: loading,
  [PAGECONTENT_LOADING_COMPLETED]: loadingCompleted,
  [LOAD_PAGECONTENT]: loadContent,
  [PAGECONTENT_DETAIL_LOADING]: loadingDetail,
  [PAGECONTENT_DETAIL_LOADING_COMPLETED]: loadingDetailCompleted,
  [LOAD_PAGECONTENT_DETAIL]: loadContentDetail

});
