import React, { useRef, useEffect } from 'react';
import { Form, Label } from 'semantic-ui-react';
import MaskedInput from 'react-text-mask';


const PhoneNumberInput = ({
                            input,
                            width,
                            label,
                            setFocus,
                            disabled,
                            padding,
                            meta: { touched, error, active }
                          }) => {
  const widthpx = width ? `${ width }px` : '100%';

  const inputEl = useRef(null);

  const divStyle = {
    padding: padding ? padding : '0 10px 0 0'
  };

  useEffect(() => {
    if (setFocus) {
      inputEl.current.inputElement.focus();
    }
  }, [setFocus]);

  return (
    <div style={ divStyle }>
      <Form.Field>
        <div
          className={ `ui ${ active &&
          ' element-focus' } ${ touched && error && ' element-error' }` }
          style={ { width: '100%' } }
        >
          { label !== null && label !== undefined &&
          <div style={ { textAlign: 'left', paddingBottom: 4 } }>
            { label }
          </div>
          }
          <div>
            <MaskedInput
              ref={ setFocus ? inputEl : null }
              { ...input }
              style={ {
                maxWidth: widthpx,
                backgroundColor: disabled ? 'rgb(245,245,245)' : 'white',
                color: disabled ? 'rgb(180,180,180)' : 'black'
              } }
              mask={ ['(', /[0]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/] }
              readOnly={ disabled }
            />
          </div>
        </div>
        { touched && error && (
          <Label basic color='red' pointing='above'>{ error }</Label>
        ) }
      </Form.Field>
    </div>
  );
};

export default PhoneNumberInput;
