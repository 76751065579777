import React, { Component, Fragment } from "react";
import { Segment, Form, Button } from "semantic-ui-react";
import {
  loadPerson,
  savePerson,
  saveChild
} from "../../features/nav/Sidebar/data/sideBarActions";
import FullBanner from "../pageContent/FullBanner";
import "./MyProfile.css";
import { compose } from "redux";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import MyProfileFields from "./MyProfileFields";
import { confirm } from "../../app/functionModals/functionModalsActions";
import LoadingComponent from "../../app/layout/LoadingComponent";
import ScrollTop from "../scrollTop/ScrollTop";
import PersonalInformationModal from "./myProfileModals/PersonalInformationModal";
import ChildInformationModal from "./myProfileModals/ChildInformationModal";
import DeliveryAddressModal from "./myProfileModals/DeliveryAddressModal";
import BirthingBuddyModal from "./myProfileModals/BirthingBuddyModal";
import scrollToComponent from "react-scroll-to-component";
import { IoLogoWindows } from "react-icons/io";
// import { diff, addedDiff, deletedDiff, updatedDiff, detailedDiff } from 'deep-object-diff';

const mapState = state => ({
  loading: state.sideBar.loading,
  person: state.sideBar.person,
  initialValues: state.sideBar.person
});

const actions = {
  loadPerson,
  confirm,
  savePerson,
  saveChild
};

class MyProfile extends Component {
  state = {
    userView: "",
    
  };

  async componentDidMount() {
    await this.props.loadPerson();
    
    const { person } = this.props;

    this.setState({ userView: person.PersonType });
    // this.setState({ userView: 'M' });
    //   this.setState({ userView: 'BB' });
  }

  //  async componentDidUpdate(){
  //   const {person} = this.props;
  //   this.setState({hasBaby: person.isBabyBornYN})
  //  }

  handleSavePerson = async values => {
    const { initialValues } = this.props;
    const questionID = localStorage.getItem("QuestionID");
    const sectionID = localStorage.getItem("SectionID");
    values["QuestionID"] = questionID;
    values["SectionID"] = sectionID;
    values["BirthingBuddyMobileNum"] = values["BirthingBuddyMobileNum"]
      .replace(/\s/g, "")
      .replace(/[{()}]/g, "")
      .replace(/-/g, "");
    let newEdd = values.EstimatedBirthDate;
    // console.log(values["SectionID"]);
    // let updatedValue= require("deep-object-diff").updatedDiff;
    // console.log(updatedDiff(initialValues, values));
    if (values["BirthingBuddyMobileNum"] === "" || values["BirthingBuddyDOB"] === null) {
      values["HasBirthingBuddyYN"] = false;
    } else {
      values["HasBirthingBuddyYN"] = true;
    }
    

    let child = {
      IsBabyBornYN: values["IsBabyBornYN"],
      BabyDOB: values["BabyDOB"],
      IsBoysYN: values["IsBoysYN"],
      IsGirlsYN: values["IsGirlsYN"],
      DeliveryTypeCode: values["DeliveryTypeCode"]
    };

    let sendValues = {
      FirstName: values["FirstName"],
      LastName: values["LastName"],
      DOB: values["DOB"],
      AddressLine1: values["AddressLine1"],
      AddressLine2: values["AddressLine2"],
      City: values["City"],
      Province: values["Province"],
      PostalCode: values["PostalCode"],
      DeliveryAddressLine1: values["DeliveryAddressLine1"],
      DeliveryAddressLine2: values["DeliveryAddressLine2"],
      DeliveryCity: values["DeliveryCity"],
      DeliveryProvince: values["DeliveryProvince"],
      DeliveryPostalCode: values["DeliveryPostalCode"],
      EstimatedDeliveryDate: values["EstimatedDeliveryDate"],
      HasBirthingBuddyYN: values["HasBirthingBuddyYN"],
      BirthingBuddyName: values["BirthingBuddyName"],
      BirthingBuddySurname: values["BirthingBuddySurname"],
      BirthingBuddyMobileNum: values["BirthingBuddyMobileNum"],
      BirthingBuddyDOB: values["BirthingBuddyDOB"],
      IsBabyBornYN: values["IsBabyBornYN"],
      IsBoysYN: values["IsBoysYN"],
      IsGirlsYN: values["IsGirlsYN"],
      DeliveryType: values["DeliveryType"],
      BabyDOB: values["BabyDOB"],
      // ProfileSectionCode: values["ProfileSectionCode"]
      ProfileSectionCode: "C",
      QuestionID: values["QuestionID"],
      sectionID: values["sectionID"]
    };

    try {
      
      await this.props.savePerson(sendValues);
     
      
      await this.props.loadPerson();
      
      this.reset();
    } catch (error) {
      // An error occured - the toast was thrown in the action
    } finally {
      this.props.loadPerson();
    }
  };

  handleCancel = () => {
    const { reset } = this.props;
    // this.props.confirm(
    //   'Are you sure you want to cancel your changes?',
    //   {
    //     onOk: () => {
    //       reset();
    //     }
    //   },
    //   'Yes',
    //   'No'
    // );
    window.location.reload();
    reset();
  };

  isLoading = () => {
    const { loading } = this.props;
    if (loading) {
      return true;
    }

    return false;
  };

  render() {
    const {
      person,
      initialValues,
      submitting,
      handleSubmit,
      pristine
    } = this.props;
    
    // let bedd = new Date(person.BEDD);
    // let  weeks = Math.floor(40 - (localStorage.getItem("WeeksPregnant") / 7));
    
    // this.setState({cheacked: initialValues.isBabyBornYN});
    // console.log(this.state.hasBaby);

    // if (person.isBabyBornYN === true) {
    //   this.setState({ hasBaby: true });
    // }

    return (
      <Fragment className="mainDivMyProfile">
        
        <FullBanner />
        {this.isLoading() && (
          <Segment attached style={{ minHeight: 418 }}>
            <LoadingComponent />
          </Segment>
        )}
        {!this.isLoading() && (
          <Form
            autoComplete="off"
            onSubmit={handleSubmit(this.handleSavePerson)}
          >
            {this.state.userView === "M" && (
              <div style={{ paddingleft: "55px" }}>
                <div ref={0}>
                  <PersonalInformationModal />
                </div>

                <br />
                <div>
                  <ChildInformationModal />
                </div>
                <br />
                {/* {weeks <= 20 && */}
                <div >
                  <DeliveryAddressModal />
                </div>
                {/* } */}
                <br />
                <div>
                  <BirthingBuddyModal />
                </div>

                <br />
              </div>
            )}

            {this.state.userView === "BB" && (
              <div>
                <div>
                  <BirthingBuddyModal />
                </div>

                <br />
                
                  <ChildInformationModal />
                
                <br />
              </div>
            )}

            <Button.Group floated="right">
              <Button
                disabled={submitting || pristine}
                loading={submitting}
                size="large"
                color="red"
                icon="save"
                content="Save"
                style={{ width: 120 }}
              />
              <Button
                disabled={submitting || pristine}
                as="a"
                onClick={this.handleCancel}
                size="large"
                icon="close"
                content="Cancel"
                style={{ width: 120 }}
              />
            </Button.Group>

            <br />
            <br />
            <br></br>
          </Form>
        )}

        <ScrollTop />
      </Fragment>
    );
  }
}

export default compose(
  connect(mapState, actions),
  reduxForm({
    form: "MyProfile",
    enableReinitialize: true,
    destroyOnUnmount: false
  })
)(MyProfile);
