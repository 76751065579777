import React from 'react'
import { Dimmer, Loader } from 'semantic-ui-react';


const LoadingComponent = ({ inverted = true }) => {
  return (
    <Dimmer inverted={ inverted } active={ true } style={{backgroundColor:'transparent'}}>
      <Loader content='Loading...' style={{color:'blue' ,backgroundColor:'transparent', width:'100%'}}/>
    </Dimmer>
  )
};

export default LoadingComponent
