import React from 'react';
import { Button } from 'semantic-ui-react';
import ReactHtmlParser from 'react-html-parser';


const FunctionAlert = ({ text, okButtonText, closeFunctionModal, closing }) => {
  return (
    <div
      className={ `functionmodal functionmodal-horizontalflip ${
        !closing ? '' : 'functionmodal-horizontalflip-closing'
      }` }
    >
      <div style={ { textAlign: 'justify' } }>{ ReactHtmlParser(text) }</div>
      <Button
        onClick={ closeFunctionModal }
        floated="right"
        style={ { width: 120, marginTop: 50 } }
        content={ okButtonText }
        icon="close"
      />
    </div>
  );
};

export default FunctionAlert;
