import React, { Component, Fragment } from "react";
import {
	loadPageContent,
	loadPageContentDetail,
} from "./data/pageContentActions";
import { compose } from "redux";
import { connect } from "react-redux";
import LoadingComponent from "../../app/layout/LoadingComponent";
import { Helmet } from "react-helmet";
import ReactHtmlParser from "react-html-parser";
import TopBanner from "../pageContent/TopBanner";
import RightAdvert from "../adverts/RightAdvert";
import TopAdvert from "../adverts/TopAdvert";
import BottomBanner from "../pageContent/BottomBanner";
import { isMobileView } from "../../app/common/utils/common";
import { Button, Grid } from "semantic-ui-react";
import { withRouter } from "react-router";
import "./PageContentPage.css";
import { isLoggedIn } from "../auth/authActions";
import { loadPerson } from "../nav/Sidebar/data/sideBarActions";
import FullBanner from "./FullBanner";
import ScrollTop from "../scrollTop/ScrollTop";
import { reset } from "redux-form";
import ReactGA from "react-ga";
import ReactDOM from "react-dom";

const mapState = (state) => ({
	loading: state.pageContent.loading,
	content: state.pageContent.content,
	loadingDetail: state.pageContent.loadingDetail,
	contentDetail: state.pageContent.contentDetail,
	person: state.sideBar.person,
});

const actions = {
	loadPageContent,
	loadPageContentDetail,
	loadPerson,
};

class PageContentPage extends Component {
	state = {
		SideBarNavOpen: false,
		pageTitel: "",
		currentImage: 0,
		images: [
			"../assets/mr-delivery-babyyumyum-mircosite-banner.png",
			"../assets/mr-delivery-babyyumyum-mircosite-banner.png",
		],
		imagesTwo: ["../assets/BumpAround.gif", "../assets/BumpAround.gif"],
		imagesThree: [
			"../assets/J&J_Baby_Yum_Yum_Banner_(1).png",
			"../assets/J&J_Baby_Yum_Yum_Banner_(1).png",
		],
		// imagesFour: [
		// 	"../assets/Calmettes-Post1.gif",
		// 	"../assets/Calmettes-Post2-zulu.gif",
		// 	"../assets/Calmettes-Post3.gif",
		// 	"../assets/Calmettes-Post4-zulu.gif",
		// ],
		links: ["https://www.mrdfood.com/", "https://www.mrdfood.com/"],
		linksTwo: ["https://www.cherrymelon.co.za", "https://www.cherrymelon.co.za"],
		linksThree: [
			"https://web.facebook.com/JohnsonsBabySA/",
			"https://web.facebook.com/JohnsonsBabySA/",
		],
		// linksFour: [
		// 	"https://www.babyyumyum.co.za/signs-your-stress-levels-are-dangerously-high/",
		// 	"https://www.babyyumyum.co.za/signs-your-stress-levels-are-dangerously-high/",
		// 	"https://www.babyyumyum.co.za/signs-your-stress-levels-are-dangerously-high/",
		// 	"https://www.babyyumyum.co.za/signs-your-stress-levels-are-dangerously-high/",
		// ],
	};
	async componentDidMount() {
		reset();

		this.interval = setInterval(() => this.switchImage(), 10000);
		this.props.loadPerson();
		const { person } = this.props;
		localStorage.setItem("WeeksPregnant", person.WeeksPregnant);
		localStorage.setItem("BabySize", person.BabySize);
		if (!isLoggedIn()) {
			this.props.history.push("/");
		}

		await this.props.loadPageContent(this.props.match.params.contentPageID);
		await this.props.loadPageContentDetail(this.props.match.params.contentPageID);

		const { match, loading, content, contentDetail } = this.props;

		const contentPageID = parseInt(match.params.contentPageID);
		const contentItem = content.filter(
			(c) => c.contentPageID === contentPageID.toString()
		);
		const contentDetailItem = contentDetail.filter(
			(c) => c.ContentPageID === contentPageID
		);
		if (!loading) {
			let title = contentItem[0].PageTitle;
			this.setState({ pageTitel: title });
			document.title = title;
			ReactGA.set({ PageTitle: contentItem[0].PageTitle });
			ReactGA.pageview(`/content/${contentItem[0].PageTitle}`);
		}
	}
	componentDidUpdate() {
		const { person } = this.props;
		localStorage.setItem("WeeksPregnant", person.WeeksPregnant);
		localStorage.setItem("BabySize", person.BabySize);
	}

	// to switch banner add image
	switchImage() {
		if (this.state.currentImage === 0) {
			this.setState({
				currentImage: 1,
			});
		} else {
			this.setState({
				currentImage: 0,
			});
		}
	}

	getHtml(content) {
		return ReactHtmlParser(
			content.replace(/\[\[SitePrefix\]\]/g, window.CONTENT_API_URL)
		);
	}

	collapsableHeadings(headings) {
		window.setTimeout(() => {
			headings.forEach((headingItem) => {
				const heading = document.getElementById(headingItem.HeadingID);
				const section = document.getElementById(headingItem.SectionID);
				heading.onclick = () => {
					if (section.classList.contains("heading-collapse-display")) {
						section.classList.add("heading-collapse-hide");
						section.classList.remove("heading-collapse-display");
					} else {
						section.classList.add("heading-collapse-display");
						section.classList.remove("heading-collapse-hide");
					}
				};
			});
		}, 500);
	}

	mrdfoodClickHandler = () => {
		ReactGA.event({
			category: "Adverts",
			action: "Mr D Food Advert",
		});
	};

	lilletsClickHandler = () => {
		ReactGA.event({
			category: "Adverts",
			action: "Lil Lets Advert",
		});
	};

	veetClickHandler = () => {
		ReactGA.event({
			category: "Adverts",
			action: "Veet Advert",
		});
	};

	probifloraClickHandler = () => {
		ReactGA.event({
			category: "Adverts",
			action: "Pobiflora Advert",
		});
	};

	JJClickHandler = () => {
		ReactGA.event({
			category: "Adverts",
			action: "Sudo-Crem Advert",
		});
	};

	sweepsouthClickHandler = () => {
		ReactGA.event({
			category: "Adverts",
			action: "Sweep South Advert",
		});
	};

	detolClickHandler = () => {
		ReactGA.event({
			category: "Adverts",
			action: "Detol Advert",
		});
	};

	CalmettesClickHandler = () => {
		ReactGA.event({
			category: "Adverts",
			action: "Calmettes Advert",
		});
	};

	render() {
		const { person } = this.props;
		const { match, loading, content, contentDetail } = this.props;
		const contentPageID = parseInt(match.params.contentPageID);
		const contentItem = content.filter(
			(c) => c.contentPageID === contentPageID.toString()
		);
		const contentDetailItem = contentDetail.filter(
			(c) => c.ContentPageID === contentPageID
		);

		let weeks;
		let event = "";

		if (40 - localStorage.getItem("WeeksPregnant") / 7 >= 0) {
			event = "pregnant";
			try {
				weeks =
					Math.floor(40 - localStorage.getItem("WeeksPregnant") / 7).toString() +
					" weeks to go";
			} catch {
				this.props.history.push(
					this.props.history.push(`/content/${localStorage.getItem("ContentID")}`)
				);
			}
		} else {
			event = "child";
			weeks = Math.floor(40 - localStorage.getItem("WeeksPregnant") / 7);
			if (weeks === -1) {
				weeks = weeks * (-1).toString() + " Week Old";
			} else {
				weeks = weeks * (-1).toString() + " Weeks Old";
			}
		}

		return (
			<Fragment>
				{/*         
        <Helmet>
          <title>
            
              {this.state.pageTitel}
              
             
          </title>
        </Helmet> */}

				<div className="fullBannerContentPage">
					<FullBanner />
				</div>

				<Grid stackable>
					<Grid.Row>
						<Grid.Column width={16}>
							<TopBanner weeks={weeks} event={event} />
						</Grid.Column>
					</Grid.Row>

					<Grid.Row>
						<Grid.Column width={12}>
							{contentPageID === 0 && (
								<div className="noContentToLoad">
									Welcome to the MyMomentum BabyYumYum maternity programme. You can look
									forward to viewing specific content on your baby's growth and
									development from week 3 of your pregnancy
								</div>
							)}
							{contentPageID === -1 && (
								<div className="noContentToLoad">
									Welcome to the MyMomentum BabyYumYum maternity programme. You can look
									forward to viewing specific content on your baby's growth and
									development from week 3 of your pregnancy
								</div>
							)}

							{(loading || contentItem.length === 0) &&
								(contentPageID !== 0 || contentPageID !== -1) && <LoadingComponent />}
							{!loading &&
								contentItem.length !== 0 &&
								(contentPageID !== 0 || contentPageID !== -1) && (
									<Fragment style={{ zIndex: -1 }}>
										{this.getHtml(contentItem[0].Content)}
										{this.collapsableHeadings(contentItem[0].ContentHeadings)}
									</Fragment>
								)}
						</Grid.Column>
						<Grid.Column width={4}>
							{this.state.currentImage !== undefined && (
								<RightAdvert
									mrdfoodClickHandler={this.mrdfoodClickHandler}
									lilletsClickHandler={this.lilletsClickHandler}
									veetClickHandler={this.veetClickHandler}
									detolClickHandler={this.detolClickHandler}
									JJClickHandler={this.JJClickHandler}
									CalmettesClickHandler={this.CalmettesClickHandler}
									sweepsouthClickHandler={this.sweepsouthClickHandler}
									imageNow={this.state.images[this.state.currentImage]}
									linkNow={this.state.links[this.state.currentImage]}
									imageNowTwo={this.state.imagesTwo[this.state.currentImage]}
									linkNowTwo={this.state.linksTwo[this.state.currentImage]}
									imageNowThree={this.state.imagesThree[this.state.currentImage]}
									linkNowThree={this.state.linksThree[this.state.currentImage]}
									// imageNowFour={this.state.imagesFour[this.state.currentImage]}
									// linkNowFour={this.state.linksFour[this.state.currentImage]}
								/>
							)}
						</Grid.Column>
					</Grid.Row>
				</Grid>
				<br />
				<p className="been">What's been</p>
				<p className="come">What's next</p>

				<Grid.Row>
					<Grid.Column width={16}>
						{loading === 0}
						{!loading !== 0 && contentDetailItem.length > 0 && (
							<Button.Group className="buttonBottom " attached="bottom">
								{contentDetailItem[0].Previous_ContentPageID !== 0 && (
									<Button
										onClick={() =>
											this.props.history.push(
												`/content/${contentDetailItem[0].Previous_ContentPageID}`
											)
										}
										className="byy-peach byy-text-darkblue"
										labelPosition="left"
										icon="left chevron"
										size={isMobileView() ? "large" : "massive"}
										// size='massive'
										textAlign="left"
										content={contentDetailItem[0].Previous_ContentPageID_Title}
									/>
								)}
								{contentDetailItem[0].Next_ContentPageID !== 0 && (
									<Button
										onClick={() =>
											this.props.history.push(
												`/content/${contentDetailItem[0].Next_ContentPageID}`
											)
										}
										className="byy-peach byy-text-darkblue"
										labelPosition="right"
										icon="right chevron"
										size={isMobileView() ? "large" : "massive"}
										// size='massive'
										textAlign="right"
										content={contentDetailItem[0].Next_ContentPageID_Title}
									/>
								)}
							</Button.Group>
						)}

						<BottomBanner />
					</Grid.Column>
				</Grid.Row>
				<ScrollTop />
			</Fragment>
		);
	}
}

export default compose(withRouter, connect(mapState, actions))(PageContentPage);
