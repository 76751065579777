import React, { Component } from "react";
import { Segment, Form, Button, Grid, Radio } from "semantic-ui-react";
import { compose } from "redux";
import { connect } from "react-redux";
import { reduxForm, Field } from "redux-form";
import RadioInput from "../../../app/common/form/RadioInput";
import CheackBoxInput from "../../../app/common/form/CheackBoxInput";
import DateInputMyProfile from "../../../app/common/form/DateInputMyProfile";
import {
	loadPerson,
	savePerson,
} from "../../../features/nav/Sidebar/data/sideBarActions";
import { textSpanIsEmpty } from "typescript";

const mapState = (state) => ({
	loading: state.sideBar.loading,
	person: state.sideBar.person,
	initialValues: state.sideBar.person,
});

const actions = {
	loadPerson,
};

class ChildInformationModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			hasBabyYes: false,
			hasBabyNo: false,
			hasBabyLable: "No",
			boy: false,
			girl: false,
			ceaserSection: false,
			naturalBirth: true,
			deliveryType: "",
			checkedC: false,
			checkedN: false,
			loaded: false,
		};
	}
	componentDidMount() {
		const { person } = this.props;
		this.setState({
			//hasBaby: person.IsBabyBornYN,
			boy: person.IsBoysYN,
			girl: person.IsGirlsYN,
			deliveryType: person.DeliveryType,
		});
		if (person.IsBabyBornYN === true) {
			this.setState({
				hasBabyYes: true,
				hasBabyNo: false,
			});
		}
		if (person.IsBabyBornYN === false) {
			this.setState({
				hasBabyYes: false,
				hasBabyNo: true,
			});
		}
		if (person.DeliveryType === "C") {
			this.setState({
				checkedC: true,
				checkedN: false,
			});
		} else if (person.DeliveryType === "N") {
			this.setState({
				checkedN: true,
				checkedC: false,
			});
		} else {
			this.setState({
				checkedN: false,
				checkedC: false,
			});
		}
	}

	handleChange = (event) => {
		if (this.state.deliveryType === "N") {
			this.setState({
				checkedN: true,
				checkedC: false,
			});
		}
		if (this.state.deliveryType === "C") {
			this.setState({
				checkedC: true,
				checkedN: false,
			});
		}
	};

	handleChangeBabyBornYesClickHandler = () => {
		if (this.state.hasBabyYes === true) {
			this.setState({ hasBabyYes: false });
		}
		if (this.state.hasBabyYes === false) {
			this.setState({ hasBabyYes: true });
		}
		if (this.state.hasBabyNo === true) {
			this.setState({ hasBabyNo: false });
		}
		// const {person} = this.props;
		// if (person.hasBaby){
		//   this.setState({hasBaby: false});
		//   console.log(this.state.hasBaby);
		// }
		// if (!person.hasBaby){
		//   this.setState({hasBaby: true});
		//   console.log(this.state.hasBaby);
		// }

		// this.setState(prevState => {
		//   return { hasBaby: !prevState.hasBaby };
		// });
	};

	handleChangeBabyBornNoClickHandler = () => {
		// this.setState(prevState => {
		//   return { checkedC: !prevState.checkedC };
		// });

		if (this.state.hasBabyNo === true) {
			this.setState({ hasBabyNo: false });
		}
		if (this.state.hasBabyNo === false) {
			this.setState({ hasBabyNo: true });
		}
		if (this.state.hasBabyYes === true) {
			this.setState({ hasBabyYes: false });
		}
	};

	handleBoyClickHandler = () => {
		this.setState((prevState) => {
			return { boy: !prevState.boy };
		});
	};

	handleGirlClickHandler = () => {
		this.setState((prevState) => {
			return { girl: !prevState.girl };
		});
	};

	handleNaturalBirthChange = () => {
		// this.setState(prevState => {
		//   return { checkedN: !prevState.checkedN };
		// });
		if (this.state.checkedN === true) {
			this.setState({ checkedN: false });
		}
		if (this.state.checkedN === false) {
			this.setState({ checkedN: true });
		}
		if (this.state.checkedC === true) {
			this.setState({ checkedC: false });
		}
	};

	handleCesareanSectionChange = () => {
		// this.setState(prevState => {
		//   return { checkedC: !prevState.checkedC };
		// });

		if (this.state.checkedC === true) {
			this.setState({ checkedC: false });
		}
		if (this.state.checkedC === false) {
			this.setState({ checkedC: true });
		}
		if (this.state.checkedN === true) {
			this.setState({ checkedN: false });
		}
	};

	handleChange = (e, { value }) => this.setState({ value });
	render() {
		const editable = false;
		return (
			<div className="mainDivMyProfile">
				<Segment attached warapped>
					<p className="title">Our new bundle of joy</p>

					<h4 className="byy-text-pink">
						To book your nurse visits, once baby is born, please email Momentum
						Wellness on:{" "}
						<a href="mailto:nursevisit@momentum.co.za" style={{ color: "#e01c21" }}>
							{" "}
							nursevisit@momentum.co.za
						</a>{" "}
						or calling
						<a href="tel:0860 117 859" style={{ color: "#e01c21" }}>
							{" "}
							0860 117 859
						</a>
					</h4>

					<Grid stackable>
						<div style={{ textAlign: "left" }}>
							<h4 className="byy-text-pink">Has baby been born?</h4>
							<Field
								name="IsBabyBornYN"
								component={RadioInput}
								// label={this.state.hasBabyLable}
								label={"Yes"}
								setFocus={editable}
								// toggle={true}
								//checked={person.IsBabyBorn}
								checked={this.state.hasBabyYes}
								onChange={this.handleChangeBabyBornYesClickHandler}
								value={true}
								setvalue={true}
								//  disabled={!editable}
							/>
							<Field
								name="IsBabyBornYN"
								component={RadioInput}
								// label={this.state.hasBabyLable}
								label={"No"}
								setFocus={editable}
								//toggle={true}
								//checked={person.IsBabyBorn}
								checked={this.state.hasBabyNo}
								onChange={this.handleChangeBabyBornNoClickHandler}
								value={false}
								setvalue={false}
								//  disabled={!editable}
							/>
							<br />
						</div>

						{this.state.hasBabyYes && (
							<Grid.Row columns={2}>
								<Grid.Column width={8}>
									<Grid>
										<Grid.Column width={4}>
											<h4>Gender</h4>
										</Grid.Column>
										<Grid.Column width={6}>
											<div style={{ textAlign: "left" }}>
												<Field
													name="IsBoysYN"
													component={CheackBoxInput}
													label="Boy(s)"
													checked={this.state.boy}
													onChange={this.handleBoyClickHandler}
													setFocus={editable}
													value={this.state.boy}
													setvalue={this.state.boy}
													//  disabled={!editable}
												/>
												<Field
													name="IsGirlsYN"
													component={CheackBoxInput}
													label="Girl(s)"
													setFocus={editable}
													checked={this.state.girl}
													value={this.state.girl}
													setvalue={this.state.girl}
													onChange={this.handleGirlClickHandler}
													//  disabled={!editable}
												/>
											</div>
										</Grid.Column>
									</Grid>
								</Grid.Column>

								<Grid.Column width={8}>
									<Grid>
										<Grid.Column width={4}>
											<h4>Delivery was...</h4>
										</Grid.Column>
										<Grid.Column width={8}>
											<div style={{ textAlign: "left" }}>
												<Field
													name="DeliveryType"
													component={RadioInput}
													// type = {"radio"}
													label="Natural birth"
													setFocus={editable}
													value={"N"}
													setvalue={"N"}
													checked={this.state.checkedN}
													onChange={this.handleNaturalBirthChange}
													//  disabled={!editable}
												/>

												<Field
													name="DeliveryType"
													component={RadioInput}
													// type = {"radio"}
													label="Cesarean section"
													setFocus={editable}
													value={"C"}
													setvalue={"C"}
													checked={this.state.checkedC}
													onChange={this.handleCesareanSectionChange}

													//  disabled={!editable}
												/>
											</div>
										</Grid.Column>
									</Grid>
								</Grid.Column>

								<Grid.Column width={4}>
									<div style={{ alignItems: "left" }}>
										<Field
											name="BabyDOB"
											component={DateInputMyProfile}
											label="Birth Date"
											dateFormat={"dd LLL yyyy"}
											minDate={new Date().setFullYear(new Date().getFullYear() - 2)}
											maxDate={new Date().setFullYear(new Date().getFullYear())}
											// setFocus={editable}
											// disabled={!editable}
										/>
									</div>
								</Grid.Column>
							</Grid.Row>
						)}
					</Grid>
				</Segment>
			</div>
		);
	}
}

export default compose(
	connect(mapState, actions)
	// reduxForm({
	//   form: "PersonalInformationModal",
	//   enableReinitialize: true,
	//   destroyOnUnmount: false
	// })
)(ChildInformationModal);
