import { compose } from "redux";

import React, { Fragment, Component } from "react";
import "../../App.css";
import ModalManager from "../modals/ModalManager";
import { Route, withRouter, Switch } from "react-router";
import HomePage from "../../features/home/homePage/HomePage";
import {
  Button,
  Container
} from "semantic-ui-react";
import NotFound from "./NotFound";
import FunctionModals from "../functionModals/FunctionModals";
import RedirectPage from "../common/utils/RedirectPage";
import { Helmet } from "react-helmet";
import PageContentPage from "../../features/pageContent/PageContentPage";
import Benefits from "../../features/benefits/Benefits";
import Footer from "../../features/footer/Footer";
import SidebarNav from "../../features/nav/Sidebar/SidebarNav";
import { isLoggedIn, logout } from "../../features/auth/authActions";
import { connect } from "react-redux";
import { loadPerson } from "../../features/nav/Sidebar/data/sideBarActions";
import { confirm } from "../../app/functionModals/functionModalsActions";
import FAQs from "../../features/faqs/FAQs";
import TermsConditions from "../../features/termsConditions/TermsConditionsPage";
import ContactBabyYumYum from "../../features/contactBabyYumYum/contactBabyYumYum";
import MyProfile from "../../features/myProfile/MyProfile";
import UsefulForms from "../../features/usefulForms/UsefulForms";
import VerifyDueDate from "../../features/verifyDueDate/VerifyDueDate";
import ProgressiveProfiling from "../../features/progressiveProfiling/ProgressiveProfiling";
import { isMobileView } from "../../app/common/utils/common";


const mapState = state => ({
  loading: state.sideBar.loading,
  person: state.sideBar.person
});

const actions = {
  loadPerson,
  logout,
  confirm
};

class App extends Component {
  state = {
    sideBarNavOpen: false,
    verifyDueDate: false
  };

  componentDidMount() {
    this.props.loadPerson();
    var isIE = !!window.MSInputMethodContext && !!document.documentMode;
    if (!isIE) {
      var ua = window.navigator.userAgent;
      isIE = ua.indexOf("MSIE ") > 0;
    }
    if (isIE) {
      alert(
        "This browser is not supported. \r\nPlease use Chrome, Microsoft Edge or Firefox."
      );
    }
  }

  sideBarNavClickHandler = () => {
    this.setState(prevState => {
      return { sideBarNavOpen: !prevState.sideBarNavOpen };
    });
  };

  benefitsClickHandler = () => {
    this.props.history.push("/benefits");
    this.setState(prevState => {
      return { sideBarNavOpen: !prevState.sideBarNavOpen };
    });
  };

  faqClickHandler = () => {
    this.props.history.push("/faqs");
    this.setState(prevState => {
      return { sideBarNavOpen: !prevState.sideBarNavOpen };
    });
  };

  contactBabyYuYumClickHandler = () => {
    this.props.history.push("/contactBabyYumYum");
    this.setState(prevState => {
      return { sideBarNavOpen: !prevState.sideBarNavOpen };
    });
  };

  termsConditionsClickHandler = () => {
    this.props.history.push("/termsConditions");

  };

  homePageClickHandler = () => {
    const {person} = this.props;
    this.props.history.push(this.props.history.push(`/content/${ person.ContentPageID}`));
    this.setState(prevState => {
      return { sideBarNavOpen: !prevState.sideBarNavOpen };
    });
  };

  logOutClickHandler = () => {
    this.props.logout();
    this.setState({ sideBarNavOpen: false });
    this.props.history.push("/");
  };

  myProfileClickHandler = () => {
    this.props.history.push("/myprofile");
    this.setState(prevState => {
      return { sideBarNavOpen: !prevState.sideBarNavOpen };
    });
  };

  usefulFormsClickHandler = () => {
    this.props.history.push("/usefulForms");
    this.setState(prevState => {
      return { sideBarNavOpen: !prevState.sideBarNavOpen };
    });
  };

  //Delete after complete verifyDueDate implementation

  verifyDueDateClickHandler = () => {
    this.props.history.push('/verifyDueDate');
  };

  progressiveProfilingClickHandler = () => {
    this.props.history.push('/progressiveProfiling')
  };

  render() {
    const { person } = this.props;
    let sideBar;

    if (!isLoggedIn() && this.state.sideBarNavOpen) {
      sideBar = (
        <SidebarNav
          sideBarNavClickHandler={ this.sideBarNavClickHandler }
          person={ person }
          logOutClickHandler={ this.logOutClickHandler }
          benefitsClickHandler={ this.benefitsClickHandler }
          faqClickHandler={ this.faqClickHandler }
          contactBabyYuYumClickHandler={ this.contactBabyYuYumClickHandler }
          homePageClickHandler={ this.homePageClickHandler }
          myProfileClickHandler={ this.myProfileClickHandler }
          usefulFormsClickHandler={ this.usefulFormsClickHandler }
        />
      );

    }

    if (this.state.sideBarNavOpen) {
      sideBar = (
        <SidebarNav
          sideBarNavClickHandler={ this.sideBarNavClickHandler }
          person={ person }
          logOutClickHandler={ this.logOutClickHandler }
          benefitsClickHandler={ this.benefitsClickHandler }
          faqClickHandler={ this.faqClickHandler }
          contactBabyYuYumClickHandler={ this.contactBabyYuYumClickHandler }
          homePageClickHandler={ this.homePageClickHandler }
          myProfileClickHandler={ this.myProfileClickHandler }
          usefulFormsClickHandler={ this.usefulFormsClickHandler }
        />
      );

    }

    return (
      <Fragment>
        <ModalManager/>
        <Route exact path="/" component={ HomePage }/>
        <Route
          path="/(.+)"
          render={ () => (
            <Fragment>
              { isLoggedIn() && isMobileView() &&(
                <Fragment >
                  <Button
                    color = 'red'
                    size = 'large'
                    onClick={ this.sideBarNavClickHandler }
                  >
                    <div style={{color:'white'}}>
                    <i className="sidebar icon"/>
                    </div>
                  </Button>
                  { sideBar }
                </Fragment>
              ) }
              { isLoggedIn() && !isMobileView() &&(
                <Fragment >
                  <Button
                    color = 'red'
                    size = 'massive'
                    onClick={ this.sideBarNavClickHandler }
                  >
                    <div style={{color:'white'}}>
                    <i className="sidebar icon"/>
                    </div>
                  </Button>
                  { sideBar }
                </Fragment>
              ) }
              <Container className="main">
                <Helmet>
                  <title>Baby Yum Yum</title>
                  <meta name="description" content="Baby Yum Yum"/>
                </Helmet>

                <Switch key={ this.props.location.key }>
                  <Route
                    path="/content/:contentPageID"
                    component={ PageContentPage }
                  />
                  <Route path="/benefits" component={ Benefits }/>
                  <Route path="/faqs" component={ FAQs }/>
                  <Route path="/contactBabyYumYum" component={ ContactBabyYumYum }/>
                  <Route path="/termsConditions" component={ TermsConditions }/>
                  <Route path="/myprofile" component={ MyProfile }/>
                  <Route path="/usefulForms" component={ UsefulForms }/>
                  <Route path="/verifyDueDate" component={ VerifyDueDate }/>
                  <Route path="/progressiveProfiling" component={ ProgressiveProfiling }/>
                  <Route path='/redirect/:token' component={ RedirectPage }/>
                  <Route component={ NotFound }/>
                </Switch>
           
              </Container>
          
            </Fragment>

          ) }
        />
        <FunctionModals/>
       
        <Footer
          termsConditionsClickHandler={ this.termsConditionsClickHandler }
          
        />
        
        
      </Fragment>

    );
  }
}

export default compose(
  withRouter,
  connect(mapState, actions)
)(App);
