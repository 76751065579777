import React, { useRef, useEffect } from 'react';
import { Form, Label } from 'semantic-ui-react';


const TextInput = ({
                     input,
                     width,
                     labelWidth,
                     label,
                     setFocus,
                     type,
                     maxLength,
                     disabled,
                     padding,
                     meta: { touched, error, active }
                   }) => {
  const labelWidthpx = labelWidth ? `${ labelWidth }px` : '120px';
  const widthpx = width ? `${ width }px` : '100%';

  const inputEl = useRef(null);

  const divStyle = {
    padding: padding ? padding : '10px 10px 0 10px',
    position: 'relative'
  };

  useEffect(() => {
    if (setFocus) {
      inputEl.current.focus();
    }
  }, [setFocus]);

  return (
    <div style={ divStyle }>
      <Form.Field>
        <div
          className={ `ui labeled input ${ active &&
          ' element-focus' } ${ touched && error && ' element-error' }` }
          style={ { width: '100%' } }
        >
          { labelWidth !== '0' && (
            <div className='ui label label' style={ { width: labelWidthpx } }>
              { label }
            </div>
          ) }
          <input
            ref={ setFocus ? inputEl : null }
            type={ type || 'text' }
            { ...input }
            style={ {
              maxWidth: widthpx,
              backgroundColor: disabled ? 'rgb(245,245,245)' : 'white',
              color: disabled ? 'rgb(180,180,180)' : 'black'
            } }
            maxLength={ maxLength }
            readOnly={ disabled }
          />
        </div>
        { touched && error && (
          <Label basic color='red' pointing='above'>
            { error }
          </Label>
        ) }
      </Form.Field>
    </div>
  );
};

export default TextInput;
