import {
  QUESTIONS_LOADING,
  QUESTIONS_LOADING_COMPLETE,
  LOAD_QUESTIONS,
  SUBMITTING_ANSWER 
} from './progressiveProfilingQuestionsConstants';
import { createReducer } from '../../../app/store/createReducer';


function getInitialState() {
  return{
    loading: false,
    questions: [],
    edd: null
  }
}

const initialState = getInitialState();

const loadingProgressive = (state)=>{
    return{
        ...state,
        loading: true
    };
};

const loadingCompleted = (state) =>{
  return{
      ...state,
      loading: false
  };
};

const loadQuestion = (state, payload) =>{
  return{
      ...state,
      questions: payload
  };
};



export default createReducer(initialState,{
  [QUESTIONS_LOADING]: loadingProgressive,
  [QUESTIONS_LOADING_COMPLETE]: loadingCompleted,
  [LOAD_QUESTIONS]: loadQuestion
});