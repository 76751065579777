import React, { Component } from 'react'
import LoadingComponent from '../../layout/LoadingComponent';


export default class RedirectPage extends Component {
  async componentDidMount() {
    localStorage.setItem('user', this.props.match.params.token.replace(/~/g, '.'));
    this.props.history.push('/');
  }

  render() {
    return (
      <LoadingComponent/>
    )
  }
}
